import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import Modal from "react-modal";
import {Text} from "@gib-ui/core/Text";
import {Select} from "@gib-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import {Button} from "@gib-ui/core/Button";
import moment from 'moment';

Modal.setAppElement('#root');

const TextRender = (className, option) => (
    <Text className={className} type="body1">
        {option.content}
    </Text>
);

export default function SubscriptionEditorModal(props) {

    const t = useTranslation('helm').t;

    const projectsOptions = [
        {
            value: 15,
            content: 'Attack Surface Management'
        },
    ];

    const getOption = (value, options) => {

        let selectedOption = {};

        options.forEach(option => {
            if (option.value === value) {
                selectedOption = option;
            }
        })

        return selectedOption;
    }

    const isTrialOptions = [
        {
            value: 1,
            content: 'Yes'
        },
        {
            value: 0,
            content: 'No'
        },
    ];

    // const [isTrial, setIsTrial] = useState(props.subscription.subscription.isTrial * 1);
    const [projectId, setProjectId] = useState(props.subscription?.subscription?.projectId);
    const [isTrial, setIsTrial] = useState((props.subscription?.subscription?.isTrial || 1) * 1);
    const [dateStart, setDateStart] = useState(props.subscription?.subscription?.dateStart || moment().format('YYYY-MM-DD'));
    const [dateEnd, setDateEnd] = useState(props.subscription?.subscription?.dateEnd || moment().format('YYYY-MM-DD'));

    const submitHandle = () => {
        let subscription = props.subscription;
        subscription.subscription.projectId = projectId;
        subscription.subscription.isTrial = isTrial;
        subscription.subscription.dateStart = dateStart;
        subscription.subscription.dateEnd = dateEnd;

        if (!subscription.subscription.projectId && !props.subscription?.subscription?.id) {
            alert('You have to choose a project');
            return;
        }

        props.onSubmit(subscription);
    }

    return(
        <Modal
            isOpen={props.isOpen}
            onRequestClose={props.onRequestClose}
            className="CompanySelect-Modal"
            overlayClassName="CompanySelect-Overlay"
        >
            <Text as="h4" color="high" type="h4" className="CompanySelect-Title">{t('Subscription')}</Text>

            {
                !props.subscription?.subscription?.id &&
                <div>
                    <Text color="high" type="body3" className="CompanySelect-Label">{t('Project')}</Text>
                    <Select
                        view="filled"
                        label={t('Project')}
                        className="CompanySelect-Select"
                        value={getOption(projectId, projectsOptions)}
                        options={projectsOptions}
                        onChange={e => setProjectId(e.target.value.value)}
                        valueRender={TextRender}
                        width="max"
                        popupWidth="max"
                    />
                </div>
            }

            <div>
                {/*<Text color="high" type="body3" className="CompanySelect-Label">{t('isTrial')}</Text>*/}
                <Select
                    view="filled"
                    label={t('isTrial')}
                    className="CompanySelect-Select"
                    value={getOption(isTrial, isTrialOptions)}
                    options={isTrialOptions}
                    onChange={e => setIsTrial(e.target.value.value)}
                    valueRender={TextRender}
                    width="max"
                    popupWidth="max"
                />
            </div>
            <div style={{marginBottom: '20px'}} >
                {/*<Text color="high" type="body3" className="CompanySelect-Label">{t('Period')}</Text>*/}
                <div style={{color: '#fff'}} >
                    <TextField
                        id="date-start"
                        label="start"
                        type="date"
                        defaultValue={dateStart}
                        className="date-picker-field"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => setDateStart(e.target.value)}
                    />
                    <TextField
                        id="date-end"
                        label="end"
                        type="date"
                        defaultValue={dateEnd}
                        className="date-picker-field"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => setDateEnd(e.target.value)}
                    />
                </div>
            </div>

            <div className="CompanySelect-Controls">
                <Button view="outline" style={{ marginRight: '4px' }} onClick={props.onRequestClose}>{t('cancel')}</Button>

                <Button view="filled-primary" onClick={submitHandle} >
                    {t('save')}
                </Button>

            </div>
        </Modal>
    );

}
