import * as querystring from 'querystring';
import defaults from './defaults';

class Api {

    _config;

    constructor(config) {
        this._config = config;
    }

    handlingResponse(request, config) {
        const { responseType, transformResponse } = config;
        return request.then((response) => {
            let responseData;

//             if (response.status === 401) {
//                 response.json().then(
//                     data => {
//                         window.location.href = data.auth_uri;
//                     }
//                 );
//             }

            if (response.status !== 200) {
                responseData = {
                    status: response.status,
                    statusText: response.statusText,
                    data: {}
                };
                return Promise.reject(responseData);
            }
            if (responseType === 'json') {
                return response.json().then(
                    data => transformResponse(data),
                    ex => Promise.reject('Response parsed ' + ex)
                );
            }

            return Promise.reject(new Error('Network response was not ok.'));
        });
    }

    request(config) {
        config = Object.assign({}, this._config, config);
        const {
            headers,
            method,
            params = {},
            credentials,
            data,
            baseURL
        } = config;
        const queryParams =
            Object.keys(params).length > 0
                ? `?${querystring.stringify(params)}`
                : '';
        const url = `${baseURL}${config.url}${queryParams}`;
        const body = data ? JSON.stringify(data) : null;

        return this.handlingResponse(
            fetch(url, { headers, credentials, method, body }),
            config
        );
    }

    get(url, config = {}) {
        const method = 'get';
        return this.request(
            Object.assign({}, config, {
                url,
                method
            })
        );
    }

    post(url, data, config = {}) {
        const method = 'post';
        return this.request(
            Object.assign({}, config, {
                url,
                data,
                method
            })
        );
    }

    all(promises = []) {
        return Promise.all(promises);
    }
}

const api = new Api(defaults);

export default api;
export { Api };
