import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '@bem-react/classname'
import { Text } from '@gib-ui/core/Text';
import { Button } from '@gib-ui/core/Button';

import { TwoFaCard } from '../../../../Components/TwoFaCard';
import { TwoFaResetPasswordQrForm } from './QrForm/TwoFaResetPassword-QrForm';

import './TwoFaResetPassword.css';

export const cnTwoFaResetPassword = cn('TwoFaResetPassword');

export const TwoFaResetPassword = () => {
    const [qrFormVisible, setQrFormVisible] = useState(false);
    const { t } = useTranslation('authentication');
    const handleProtectClick = () => {
        setQrFormVisible(true);
    }
    return (
        <div className={cnTwoFaResetPassword()}>
            {
                !qrFormVisible ? (
                    <div className={cnTwoFaResetPassword('ProtectForm')}>
                        <Text as="h3" type="h3" color="high" weight="bold" className={cnTwoFaResetPassword('Title')}>{t('passwordChanged.title')}</Text>
                        <Text type="body3" color="medium" className={cnTwoFaResetPassword('Description')}>{t('passwordChanged.description')}</Text>
                        <Button as="a" href="/" view="outline" width="max" className={cnTwoFaResetPassword('BackBtn')}>{t('passwordChanged.backToSignIn')}</Button>
                        <TwoFaCard reset onProtectClick={handleProtectClick} />
                    </div>
                ) : <TwoFaResetPasswordQrForm />
            }
        </div>
    )
}