import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import BackToHomeButton from "../../../../Components/BackToHomeButton";

const PasswordRecoveryFinishPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const PasswordRecoveryFinishPageTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 121.7%;

  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 8px;
`
const PasswordRecoveryFinishPageDescription = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  color: rgba(255, 255, 255, 0.6);
  mix-blend-mode: normal;
  margin-bottom: 24px;
`

function PasswordRecoveryFinishPage() {
  const { t } = useTranslation('authentication')
  return (
    <PasswordRecoveryFinishPageWrapper>
      <PasswordRecoveryFinishPageTitle>
        {t('passwordRecentSent.title')}
      </PasswordRecoveryFinishPageTitle>
      <PasswordRecoveryFinishPageDescription>
        {t('passwordRecentSent.description.partOne')}
        <br />
        <br />
        {t('passwordRecentSent.description.partTwo')}
      </PasswordRecoveryFinishPageDescription>

        <BackToHomeButton >{t('back')}</BackToHomeButton>
    </PasswordRecoveryFinishPageWrapper>
  )
}

export default PasswordRecoveryFinishPage
