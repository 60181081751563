import React from 'react';

export const TwoFaModalIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="112" height="112" viewBox="0 0 112 112" fill="none">
            <path d="M21 22L55.9999 7L91 22V59.4153C91 72.3333 84.7614 84.4562 74.2495 91.9646L55.9999 105L37.7504 91.9646C27.2386 84.4562 21 72.3333 21 59.4153V22Z" fill="white" fill-opacity="0.12" />
            <path d="M64 46.9682V40C64 35.5817 60.4183 32 56 32V32C51.5817 32 48 35.5817 48 40V47" stroke="#8DCAFE" strokeOpacity="0.6" strokeWidth="4" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M42 48C40.8954 48 40 48.8954 40 50V60C40 68.8366 47.1634 76 56 76C64.8366 76 72 68.8366 72 60V50C72 48.8954 71.1046 48 70 48H42ZM50.8284 59.1716L48 62L53.6569 67.6569L64.9706 56.3431L62.1421 53.5147L53.6569 62L50.8284 59.1716Z" fill="#8DCAFE" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M91 59.4152V22L56 7V105L74.2495 91.9646C84.7614 84.4561 91 72.3333 91 59.4152Z" fill="white" fill-opacity="0.08" />
        </svg>
    )
}