import React from 'react'

// Список и параметры проектов, которые нужно выводить на странице
function getProjectNames(t) {

    return [
        {
            name: 'Threat Intelligence',
            codes: ['ci'],
            firstColor: '#4B8193',
            secondColor: '#8932F7',
            selectOptions: [],
            defaultSelect: 'Russia',
            indicatorColor: '#82CA71',
            background: 'linear-gradient(65deg, #4B8193 -5%, rgba(105, 91, 195, 0.5) 50%, rgba(137, 50, 247, 0) 100%)'
        },
        {
            name: t('products.name.SBSP'),
            codes: ['sb', 'sb2', 'sb3', 'sp'],
            firstColor: '#FFAE34',
            secondColor: '#8932F7',
            selectOptions: [],
            defaultSelect: 'Singapore',
            indicatorColor: '#82CA71',
            selectDisabled: true,
            background: 'linear-gradient(65deg, #FFAE34 -5%, rgba(199, 115, 144, 0.5) 50%, rgba(137, 50, 247, 0) 100%)'
        },
        {
            name: 'Managed XDR',
            codes: ['tds', 'huntbox-tds'],
            firstColor: '#3A3FA2',
            secondColor: '#8932F7',
            selectOptions: [],
            defaultSelect: 'Russia',
            indicatorColor: '#82CA71',
            indicatorText: 'Trial period for 7 days',
            background: 'linear-gradient(65deg, #3A3FA2 -5%, rgba(97, 57, 204, 0.5) 50%, rgba(137, 50, 247, 0) 100%)'
        },
        {
            name: <span>Digital<br/>Risk Protection</span>,
            codes: ['bp'],
            firstColor: '#19285F',
            secondColor: '#8932F7',
            selectOptions: [],
            defaultSelect: 'Russia',
            indicatorColor: '#82CA71',
            background: 'linear-gradient(65deg, #B71D54 -5%, rgba(161, 39, 161, 0.5) 50%, rgba(137, 50, 247, 0) 100%)'
        },
        {
            name: 'Surfix',
            codes: ['rt'],
            firstColor: '#A7C7AB',
            secondColor: '#8932F7',
            selectOptions: [],
            defaultSelect: 'Russia',
            indicatorColor: '#82CA71',
            background: 'linear-gradient(65deg, #A7C7AB -5%, rgba(152, 125, 209, 0.5) 50%, rgba(137, 50, 247, 0) 100%)'
        },
        {
//             name: <span>Threat<br/>Intelligence 2.0<Badge style={{marginTop: '-5px'}} >beta</Badge></span>,
            name: <span>Threat Intelligence</span>,
            codes: ['ai'],
            firstColor: '#6E27C3',
            secondColor: '#3E77E4',
            selectOptions: [],
            defaultSelect: 'Russia',
            indicatorColor: '#82CA71',
            background: 'linear-gradient(65deg, #B45B5B -1.03%, rgba(127, 68, 173, 0.5) 50%, rgba(75, 46, 255, 0) 100%)',
        },
    ];

}

// Получение списка настроек проектов с учетом групп, т.е. по каждому проекту в группе добавляется ключ в объект
function getProjectsParams(t) {

    let projectsParams = {};

    getProjectNames(t).forEach(params => {

        params.codes.forEach(code => {

            projectsParams[code] = params;

        });

    });

    return projectsParams;
}



function getAvailableProducts(projects, t) {

    let availableProducts = [];
    let projectsParams = getProjectsParams(t);

    Object.keys(projects).forEach((projectCode) => {

        if (!projectsParams.hasOwnProperty(projectCode) || !projects[projectCode].access) {
            return;
        }

        let params = projectsParams[projectCode];

        availableProducts.push({
            name: params.name || 'Undefined',
            background: params.background,
            badge: params.badge && params.badge,
            firstColor: params.firstColor,
            secondColor: params.secondColor,
            selectOptions: projects[projectCode].options,
            defaultSelect: params.defaultSelect || (projects[projectCode].options[0] || {id: '-', name: '-'}).name,
            indicatorColor: params.indicatorColor,
            indicatorText: 'Trial period for 7 days',
        });

    });

    return availableProducts;
}

function checkRedirectUri(back, projects) {

    let letGo = false;

    Object.keys(projects).forEach(code => {

        let settings = projects[code];

        settings.url.forEach(url => {

            if (
                url.replace(/^(?:https?:\/\/)([^/]+)(.*)/, '$1') !== back.replace(/^(?:https?:\/\/)([^/]+)(.*)/, '$1')
                &&
                document.location.href.replace(/^(?:https?:\/\/)([^/]+)(.*)/, '$1') !== back.replace(/^(?:https?:\/\/)([^/]+)(.*)/, '$1')

            ) {

                return;

            }

            letGo = true;

        });

    });

    return letGo;
}

export {
    getAvailableProducts,
    checkRedirectUri
}
