import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '../Components/Context/AppContext';
import Badge from '../Components/Badge';

const getAllProjects = (t) =>
    [
        {
            name: <span>Threat Intelligence<Badge style={{marginTop: '-5px'}} >archive</Badge></span>,
            codes: ['ci'],
            description: t('products.description.TI'),
            code: 'ci',
            firstColor: '#4B8193',
            secondColor: '#8932F7',
            selectOptions: [],
            defaultSelect: 'Russia',
            indicatorColor: '#82CA71',
            background: 'linear-gradient(65deg, #4B8193 -5%, rgba(105, 91, 195, 0.5) 50%, rgba(137, 50, 247, 0) 100%)'
        },
        {
            name: t('products.name.SBSP'),
            codes: ['sb', 'sb2', 'sb3', 'sp'],
            description: t('products.description.SBSP'),
            code: 'sb',
            firstColor: '#FFAE34',
            secondColor: '#8932F7',
            selectOptions: [],
            defaultSelect: 'Singapore',
            indicatorColor: '#82CA71',
            selectDisabled: true,
            background: 'linear-gradient(65deg, #FFAE34 -5%, rgba(199, 115, 144, 0.5) 50%, rgba(137, 50, 247, 0) 100%)'
        },
        {
            name: 'Managed XDR',
            description: t('products.description.TDS2'),
            codes: ['tds', 'huntbox-tds'],
            code: 'tds',
            firstColor: '#3A3FA2',
            secondColor: '#8932F7',
            selectOptions: [],
            defaultSelect: 'Russia',
            indicatorColor: '#82CA71',
            indicatorText: 'Trial period for 7 days',
            background: 'linear-gradient(65deg, #3A3FA2 -5%, rgba(97, 57, 204, 0.5) 50%, rgba(137, 50, 247, 0) 100%)'
        },
        {
            name: <span>Digital<br />Risk Protection</span>,
            description: t('products.description.brandProtection'),
            codes: ['bp'],
            code: 'bp',
            firstColor: '#19285F',
            secondColor: '#8932F7',
            selectOptions: [],
            defaultSelect: 'Russia',
            indicatorColor: '#82CA71',
            background: 'linear-gradient(65deg, #B71D54 -5%, rgba(161, 39, 161, 0.5) 50%, rgba(137, 50, 247, 0) 100%)'
        },
        {
            name: <div style={{ display: 'flex', alignItems: 'center' }}>Attack Surface Management</div>,
            description: t('products.description.SU'),
            codes: ['rt'],
            code: 'rt',
            firstColor: '#A7C7AB',
            secondColor: '#8932F7',
            selectOptions: [],
            defaultSelect: 'Russia',
            indicatorColor: '#82CA71',
            background: 'linear-gradient(65deg, #A7C7AB -5%, rgba(152, 125, 209, 0.5) 50%, rgba(137, 50, 247, 0) 100%)'
        },
        {
            name: <div style={{ display: 'flex', alignItems: 'center' }}>Trebuchet</div>,
            description: t('products.description.trebuchet'),
            codes: ['tr'],
            code: 'tr',
            firstColor: '#A7C7AB',
            secondColor: '#8932F7',
            selectOptions: [],
            defaultSelect: 'Russia',
            indicatorColor: '#82CA71',
            background: 'linear-gradient(65deg, #A7C7AB -5%, rgba(152, 125, 209, 0.5) 50%, rgba(137, 50, 247, 0) 100%)'
        },
        {
            //             name: <span>Threat<br/>Intelligence 2.0<Badge style={{marginTop: '-5px'}} >beta</Badge></span>,
            name: <span>Threat Intelligence</span>,
            description: t('products.description.attributionIntelligence'),
            codes: ['ai'],
            code: 'ai',
            firstColor: '#6E27C3',
            secondColor: '#3E77E4',
            selectOptions: [],
            defaultSelect: 'Russia',
            indicatorColor: '#82CA71',
            background: 'linear-gradient(65deg, #B45B5B -1.03%, rgba(127, 68, 173, 0.5) 50%, rgba(75, 46, 255, 0) 100%)',
        },
        {
            name: 'Business Email Protection',
            description: t('products.description.Atmosphere'),
            codes: ['huntbox-module-atmosphere'],
            code: 'huntbox-module-atmosphere',
            firstColor: '#3A3FA2',
            secondColor: '#8932F7',
            selectOptions: [],
            defaultSelect: 'Russia',
            indicatorColor: '#82CA71',
            indicatorText: 'Trial period for 7 days',
            background: 'linear-gradient(65deg, #3A3FA2 -5%, rgba(97, 57, 204, 0.5) 50%, rgba(137, 50, 247, 0) 100%)'
        },
    ]

export const useProducts = () => {
    const at = useTranslation('authentication').t;
    const appContext = useContext(AppContext);
    const projects = appContext.sessionParams.projects;
    const allProducts = getAllProjects(at);
    const productList = allProducts.reduce((acc, params, i) => {
        params.codes.forEach(code => {
            acc[code] = params;
        });
        return acc;
    }, {});
    const availableProducts = Object.keys(projects.active || [])
        .filter((projectCode) => productList.hasOwnProperty(projectCode) && projects.active[projectCode].access)
        .map((projectCode) => {
            const params = productList[projectCode];
            if (!params) {
                return {}
            }
            return {
                name: params.name || 'Undefined',
                description: params.description,
                background: params.background,
                code: params.code,
                badge: params.badge && params.badge,
                firstColor: params.firstColor,
                secondColor: params.secondColor,
                subscription: projects.active[projectCode].subscription,
                selectOptions: projects.active[projectCode].options,
                defaultSelect: params.defaultSelect || (projects.active[projectCode].options[0] || { id: '-', name: '-' }).name,
                indicatorColor: params.indicatorColor,
                indicatorText: 'Trial period for 7 days',
            }
        })
    const otherProducts = Object.keys(projects.other || [])
        .map((projectCode) => {
            const params = productList[projectCode];
            if (!params) {
                return {}
            }
            return {
                name: params.name || 'Undefined',
                description: params.description,
                background: params.background,
                code: params.code,
                badge: params.badge && params.badge,
                firstColor: params.firstColor,
                secondColor: params.secondColor,
                indicatorColor: params.indicatorColor,
                indicatorText: 'Trial period for 7 days',
                subscription: projects.other[projectCode].subscription,
                trialLink: projects.other[projectCode].trialLink,
                ajax: projects.other[projectCode].ajax,
                successMessage: projects.other[projectCode].successMessage,
            }
        })

    return {
        availableProducts,
        otherProducts
    };
}
