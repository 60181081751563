import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import './styles.css'

import Textfield from '../../../../Components/Textfield.js'
import Button from '../../../../Components/Button.js'
import ErrorDescription from '../../../../Components/Error.js'
import BackToHomeButton from '../../../../Components/BackToHomeButton.js'
import PasswordRecoveryFinishPage from './PasswordRecoveryFinishPage'

import { api } from '../../../../Components/Api/index'

const PasswordRecoveryWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PasswordRecoveryForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const PasswordRecoveryTitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 121.7%;

  color: rgba(255, 255, 255, 0.87);

  margin-bottom: 8px;
`;

const PasswordRecoveryDescription = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  color: rgba(255, 255, 255, 0.6);
  mix-blend-mode: normal;

  margin-bottom: 32px;
`;


function PasswordRecovery(props) {

  const [email, setEmail] = useState('');
  const [formAlert, setAlert] = useState(null);
  const [linkSent, setLinkSent] = useState(false);
  const [processing, setProcessing] = useState(false);
  const { t } = useTranslation('authentication');
  // обработчик события подтверждения формы
  async function handleSubmit(event) {

      event.preventDefault();

      if (!validate() || processing) {
          return;
      }

      setProcessing(true);

      let response = await api.post('/recovery_password_process', {email: email});

      if (response.alert !== 'password_reseted') {
          setAlert(response.alert);
      } else {
          setLinkSent(true);
      }

      setProcessing(false);
  }

  function validate() {
      let error = null;
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (email.length === 0 || !re.test(String(email).toLowerCase())) {
          error = 'email_invalid';
      }

      setAlert(error);

      return error === null;
  }

  return (

      linkSent

      ?

        <PasswordRecoveryFinishPage />

      :

        <PasswordRecoveryWrapper>
          <PasswordRecoveryTitle>
            {t('passwordRecovery.title')}
          </PasswordRecoveryTitle>
          <PasswordRecoveryDescription>
            {t('passwordRecovery.description')}
          </PasswordRecoveryDescription>

          <PasswordRecoveryForm onSubmit={handleSubmit}>
            <Textfield
              error={formAlert}
              className="PasswordRecovery__textfield"
              type="text"
              label={t('passwordRecovery.yourEmail')}
              value={email}
              onChange={e => {setEmail(e.target.value)}}
            />

            <ErrorDescription style={{marginBottom: '20px'}} code={formAlert}/>

            <Button className="PasswordRecovery__button" type="submit">
              {t('resetPassword')}
            </Button>
          </PasswordRecoveryForm>
          <BackToHomeButton />
        </PasswordRecoveryWrapper>
  )
}

export default PasswordRecovery
