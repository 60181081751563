import React from 'react'
function CardButton({ className, ...props }) {
  return (
    <span className={className} style={{ cursor: 'pointer' }} {...props}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM13.8343 11.7657L18.0685 16L13.8343 20.2343L14.9656 21.3656L19.7656 16.5657L20.3313 16L19.7656 15.4343L14.9656 10.6344L13.8343 11.7657Z"
        />
      </svg>
    </span>
  )
}

export default CardButton
