import { useState } from 'react';
import { api } from '../Components/Api';

export const useGenerateTwoFa = (token) => {
    const [data, setData] = useState(undefined);
    const [error, setError] = useState(undefined);

    const generate = async () => {
        try {
            const body = {};
            if (token) {
                body.token = token;
            }
            const response = await api.post('/2fa/generate', body);
            setData(response);
        } catch (ex) {
            setError(ex);
        }
    }

    return {
        data,
        error,
        generate
    }
}