/**
 * Card - карточка продукта с именем, индиактором(опционально),
 * селектом(опционально) и кнопкой
 *
 * Пропсы:
 *  name: string - тайтл карточки
 *  fisrtColor: string - начальный цвет bg-градиента (!!только HEX!!)
 *  secondColor: string - конечный цвет bg-градиента (!!только HEX!!)
 *  selectOptions: array - список опций селекта.
 *    Если опций нет - селект не отображается
 *
 * defaultSelect: string - значение селекта по умлочанию
 * selectDisabled: boolean - выключение селекта
 * indicatorColor: string - цвет индикатора
 * indicatorText: string - текст внутри индикатора.
 *    Если текста нет - индиактор не отбражается.
 *
 */

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import './Card.css'

import Select from '../Select/Select.js'
import CardButton from './CardButton.js'
import IndicatorTag from '../IndicatorTag.js'
import { getMonthList } from '../ProductCard/ProductCard';
const StyledCardWrapper = styled.a`
  border-radius: 16px;
  background: ${({ background }) => `${background};`};
  text-decoration: none;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  
  :hover {
    background: ${({ background }) => `${background},rgba(255,255,255,0.04)`};
  }
  
`;


const getFormatDays = (date, t) => {
  if (!date) {
    return null;
  }
  const tDate = new Date(date);
  const tDays = tDate.getDate();
  const tMonth = tDate.getMonth();
  const tYear = tDate.getFullYear();
  const tMonthName = getMonthList(t)[tMonth];
  const targetDate = tDate.getTime();
  const curDate = new Date().getTime();
  const daysDiff = Math.round((Math.max(curDate, targetDate) - Math.min(curDate, targetDate)) / 86400000).toLocaleString();
  const isShortDate = daysDiff <= 31;
  return {
    isShortDate,
    date: daysDiff <= 31 ? `${daysDiff} ${t('days')}` : `${tDays} ${tMonthName} ${tYear}`
  }
}
const StyledCardName = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: rgba(255, 255, 255, 0.87);
  mix-blend-mode: normal;
  width: 190px;
`;
function Card(props) {
  const at = useTranslation('authentication').t;
  let {
    className,
    name,
    firstColor,
    secondColor,
    background,
    selectOptions,
    defaultSelect,
    selectDisabled,
    subscription
  } = props;
  let defaultUrl = selectOptions && selectOptions[0].id;
  let defaultExists = false;

  let indicatorDate = null;
  if (subscription) {
    if (subscription.isTrial) {
      indicatorDate = `${at('trialPeriod')} ${getFormatDays(subscription.dateEnd, at).date}`;
    } else {
      const date = getFormatDays(subscription.dateEnd, at);
      indicatorDate = date.isShortDate ? `${at('available')} ${date.date}` : `${at('availableTill')} ${date.date}`
    }
  }

  if (selectOptions) {

    selectOptions.forEach(option => {

      if (option.name === defaultSelect) {
        defaultUrl = option.id;
        defaultExists = true;
      }

    });

  }

  if (!defaultExists) {
    defaultSelect = selectOptions && selectOptions[0].name;
  }

  const [selectedUrl, setSelectedUrl] = useState(defaultUrl);

  // обработчик события выбора опции в селекте
  function handleSelectChange(item, e) {
    setSelectedUrl(item.id);
  }

  return (
    <StyledCardWrapper
      background={background}
      className={className}
      firstColor={firstColor}
      secondColor={secondColor}
      // onClick={e => {
      //   e.preventDefault();
      //   console.log(selectedUrl);
      //   return false
      // }}
      href={selectedUrl}
    >
      <div>
        <StyledCardName className="Card__title">{name}</StyledCardName>

        {subscription && subscription.isExpired &&
          <IndicatorTag indicatorColor="#FA665B">{`${at('expired')} ${getFormatDays(subscription.dateEnd, at).date}`}</IndicatorTag>
        }

        {subscription && !subscription.isExpired &&
          <IndicatorTag indicatorColor="#02BB9F">{indicatorDate}</IndicatorTag>
        }

      </div>
      <div className="Card__bottom-controls">
        <CardButton className="Card__Button" />
        {selectOptions && selectOptions.length > 1 && (
          <Select
            options={selectOptions}
            default={defaultSelect}
            disabled={selectDisabled}
            onChange={handleSelectChange}
          />
        )}
      </div>
    </StyledCardWrapper>
  )
}

export default Card
