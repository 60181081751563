import React, { useState, useContext } from 'react'
import { Link as RouteLink, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import './styles.css'

import Textfield from '../../../../Components/Textfield.js'
import Button from '../../../../Components/Button.js'

import { api } from '../../../../Components/Api/index'
import AppContext from '../../../../Components/Context/AppContext'
import ErrorDescription from "../../../../Components/Error";
import { authRedirect } from '../../../../Components/Utils/authRedirect';
const LoginFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
`;
const LoginFormTitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;

  color: #ffffff;

  margin-bottom: 8px;
`;
const LoginFormDescription = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  color: rgba(255, 255, 255, 0.6);
  mix-blend-mode: normal;

  margin-bottom: 24px;
`;

function LoginForm(props) {

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [formAlert, setAlert] = useState(null);
    const [processing, setProcessing] = useState(false);
    const history = useHistory();

    const { sessionParams, setSessionParams } = useContext(AppContext);
    const { t } = useTranslation('authentication');

    // обработчик события подтверждения формы
    async function handleSubmit(event) {
        event.preventDefault();

        if (!validate() || processing) {
            return;
        }

        setProcessing(true);
        const _login = login.trim();
        const _password = password.trim();
        try {
            let response = await api.post(
                '/auth',
                {
                    login: _login,
                    password: _password
                },
                {
                    baseURL: ''
                }
            );

            //       let response = {};
            if (response.success && response.success) {

                window.GIB.user = response.user;
                window.GIB.isAuthenticated = true;
                let rememberMe = response.rememberMe || false;

                sessionParams.projects = response.projects || sessionParams.projects;
                sessionParams.companies = response.companies || sessionParams.companies;
                sessionParams.user = response.user || sessionParams.user;
                sessionParams.username = response.username || sessionParams.username;
                sessionParams.twoFaIsOn = response.twoFaIsOn || sessionParams.twoFaIsOn;
                sessionParams.isAuthenticated = true;
                setSessionParams({ ...sessionParams });

                if (response.twoFaIsOn && !rememberMe) {
                    history.push('/2fa/verification' + document.location.search);
                    return;
                } else {

                    if (window.gib && window.gib.setIdentity) {
                        window.gib.setIdentity(response.user);
                    }

                    const redirect = authRedirect(response);

                    if (redirect) {
                        return;
                    }

                }

            } else if (response.redirectTo && response.redirectTo) {
                setAlert('need_redirect_to_another_region');

                setTimeout(function () {
                    document.location.href = response.redirectTo;
                    return true;
                }, 5000);

            } else {
                setAlert(response.error || 'login_or_password_invalid');
            }

            setProcessing(false);
        } catch (ex) {
            console.log(ex);
            setProcessing(false);
        }

    }

    function validate() {
        let error = null;

        if (!login) {
            error = 'login_is_empty';
        } else if (!password) {
            error = 'password_is_empty';
        }

        setAlert(error);

        return null === error;
    }

    return (
        <LoginFormWrapper onSubmit={handleSubmit}>
            <LoginFormTitle>{t('loginForm.title')}</LoginFormTitle>
            <LoginFormDescription>{t('loginForm.description')}</LoginFormDescription>

            <Textfield
                id={"sso-auth-field-first"}
                error={formAlert}
                style={{ marginBottom: '12px' }}
                className="LoginForm__textfield"
                type="text"
                label={t('loginForm.email')}
                onChange={(event) => setLogin(event.target.value)}
                value={login}
                autoComplete="on"
            />

            <Textfield
                id={"sso-auth-field-second"}
                error={formAlert}
                className="LoginForm__textfield"
                type="password"
                label={t('loginForm.password')}
                onChange={(event) => setPassword(event.target.value)}
                value={password}
            />

            <ErrorDescription code={formAlert} />

            <div className="LoginForm__rememberMe">
                <RouteLink to="/recovery_password" >{t('loginForm.forgotPassword')}</RouteLink>
            </div>
            <Button type="submit" >{t('loginForm.signInButton')}</Button>
            <div className="LoginForm__samlLink">
                <RouteLink to="/saml_auth" >{t('loginForm.signInWithSaml')}</RouteLink>
            </div>
        </LoginFormWrapper>
    )
}

export default LoginForm
