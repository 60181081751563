import React, { memo, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { cn } from '@bem-react/classname';
import { Text } from '@gib-ui/core/Text';
import { TextField } from '@gib-ui/core/TextField';
import { Checkbox } from '@gib-ui/core/Checkbox';
import { Button } from '@gib-ui/core/Button';

import { useVerification } from '../../hooks/useVerifications';
import { authRedirect } from '../Utils/authRedirect';
import AppContext from '../Context/AppContext';

import './TwoFaVerification.css';

export const cnTwoFaVerification = cn('TwoFaVerification');

export const TwoFaVerification = memo(() => {
    const t = useTranslation('twoFa').t;
    const history = useHistory();
    const { sessionParams, setSessionParams } = useContext(AppContext);
    const [code, setCode] = useState('');
    const [processing, setProcessing] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const { error, verification, data, setError } = useVerification();
    const [lengthError, setLengthError] = useState(false);

    const handleChangeCode = async (event) => {
        const value = event.target.value;
        if ((/^[0-9]+$/.test(value) || value === '') && value.length <= 6) {
            setError(undefined)
            setCode(event.target.value);
        }

        if (value.length === 6) {
            setProcessing(true);
            await verification(value, rememberMe);
        }
    }

    const handleChangeRememberMe = (event) => {
        setRememberMe(!rememberMe);
    }

    const handleSendCode = async (event) => {
        event.preventDefault();
        if (code.length === 6) {
            setProcessing(true);
            await verification(code, rememberMe);
        } else {
            setLengthError(true);
        }
    }

    useEffect(() => {
        if (data) {
            sessionParams.projects = data.projects;
            sessionParams.companies = data.companies;
            sessionParams.user = data.user;
            sessionParams.username = data.username;
            sessionParams.isAuthenticated = true;
            const redirect = authRedirect(data);
            if (!redirect) {
                setSessionParams(sessionParams);
                history.push('/');
            }
        }
    }, [data])

    useEffect(() => {

        if (error) {
            setCode('');
            setProcessing(false);
        }

    }, [error])

    const errorMsg = lengthError ? 'Incorrect code' : error ? error.message : '';

    return (
        <div className={cnTwoFaVerification()}>
            <form onSubmit={handleSendCode}>
                <Text as="h2" type="h2" color="high" className={cnTwoFaVerification('Title')}>{t('twoFa')}</Text>
                <Text type="body3" color="medium" className={cnTwoFaVerification('Description')}>
                    Please enter the code from your two-step verification app <Text type="body3" color="green">(Google Authenticator or Duo Authentication)</Text>.
            </Text>
                <TextField
                    view="filled"
                    value={code}
                    label={t('code')}
                    error={!!error || lengthError}
                    helperText={errorMsg}
                    onChange={handleChangeCode}
                    autoFocus={true}
                />
                <div className={cnTwoFaVerification('RememberMeCheckBox')} >
                    <Checkbox label={t('remember_me')} checked={rememberMe} onChange={handleChangeRememberMe} />
                </div>
                <Button
                    disabled={processing}
                    type="submit"
                    view="filled-brand"
                    width="max"
                    style={{ marginBottom: '8px' }}
                >{t('next')}</Button>
                <Button type="button" as="a" href="/logout" view="filled-brand" width="max">{t('back')}</Button>
            </form>

        </div>
    )
})
