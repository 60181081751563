import React from 'react';
import Modal from 'react-modal';
import { Text } from '@gib-ui/core/Text';
import { Button } from '@gib-ui/core/Button';

import './MessageModal.css';

Modal.setAppElement('#root');

export const MessageModal = ({ title, text, isOpen, trialLink, onRequestClose }) => {

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="MessageModal-Modal"
            overlayClassName="MessageModal-Overlay"
        >
            <Text as="h4" color="high" type="h4" className="MessageModal-Title">{title}</Text>
            <Text color="high" type="body3" className="MessageModal-Label">{text}</Text>

            <div className="MessageModal-Controls">
                <Button view="outline" style={{ marginRight: '4px' }} onClick={onRequestClose}>OK</Button>
            </div>
        </Modal>
    )
}
