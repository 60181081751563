/**
 * RollingProducts - компонент, поочередно раскрывающий информацию
 * о продуктах на странице логина
 *
 * Пропсы:
 *  productList: array - список объектов со свойствами продукта
 *    {
 *      name: string - имя продукта
 *      description: string - описание продукта
 *    }
 *  duration: number - время задержки раскрытого продукта (мс)
 */

import React from 'react'
import styled from 'styled-components'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { withTranslation } from 'react-i18next';
import './styles.css'
import ProgressBar from './ProgressBar.js'

import Button from '../Button.js'
import Badge from '../Badge.js'
import Link from "../Link";

const StyledProductName = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  mix-blend-mode: normal;

  margin-bottom: ${({ extended }) => (extended ? '0' : '24px')};

  color: ${({ extended }) => (extended ? '#000000' : '#FFFFFF')};
  cursor: ${({ extended }) => (extended ? 'unset' : 'pointer')};
  
  @media (min-width: 1680px) {
    font-size: 24px;
    line-height: 32px;
  }
  
`

const StyledExtendedProduct = styled.div`
  background: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  padding: 16px;

  width: 370px;
  margin-bottom: 24px;
  margin-left: -16px;
  overflow: hidden;
  position: relative;
`
const StyledExtendedProductDescription = styled.div`
  margin: 10px 0 10px 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  color: rgba(0, 0, 0, 0.74);
  
  @media (min-width: 1680px) {
    font-size: 16px;
    line-height: 23px;
  }
  
`


class RollingProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentExtendIndex: 0,
      currentProgressWidth: 0,
    }
  }

  updateRoller() {
    this.setState((state, props) => {
      if (state.currentExtendIndex === props.productList.length - 1) {
        this.props.onChange(this.props.productList[0]);

        return {
          currentExtendIndex: 0,
          currentProgressWidth: 0,
        }
      }

      const newCurrentIndex = state.currentExtendIndex + 1;
      this.props.onChange(this.props.productList[newCurrentIndex]);

      return {
        currentExtendIndex: newCurrentIndex,
        currentProgressWidth: 0,
      };
    })
  }

  updateProgress() {
    this.setState((state) => {
      return {
        currentProgressWidth: state.currentProgressWidth + 0.5,
      }
    })
  }

  componentDidMount() {
    const progressUnit = Math.round(this.props.duration / 200);
    this.progressInterval = setInterval(
      () => this.updateProgress(),
      progressUnit
    );

    this.rollingInterval = setInterval(
      () => this.updateRoller(),
      this.props.duration
    )
  }

  componentWillUnmount() {
    clearInterval(this.rollingInterval);
    clearInterval(this.progressInterval);
  }

  handleClickNotExtendedProduct(index) {
    this.setState(() => {
      this.props.onChange(this.props.productList[index]);
      return { currentExtendIndex: index, currentProgressWidth: 0 }
    });

    this.componentWillUnmount();
    this.componentDidMount();
  }

  render() {

    const { t } = this.props;

    const { className, productList } = this.props;

    return (
      <TransitionGroup className={className}>
        {productList.map((product, index) => {
          const key = `Rolling_${product.i}`;

          return this.state.currentExtendIndex === index ? (
            <CSSTransition key={key} timeout={500} classNames="fade">
              <StyledExtendedProduct>
                <StyledProductName extended >{product.name}{product.badge && <Badge style={{ background: 'rgba(0, 0, 0, 0.74)', color: '#fff' }} >{product.badge}</Badge>}</StyledProductName>

                <StyledExtendedProductDescription >
                  {product.description}
                </StyledExtendedProductDescription>

                <Link href={product.link} target="_blank" >
                  <Button>
                    {t('products.tryDemo')}
                  </Button>
                </Link>

                <ProgressBar percent={this.state.currentProgressWidth} />
              </StyledExtendedProduct>
            </CSSTransition>
          ) : (
              <CSSTransition key={key} timeout={500} classNames="fade">
                <StyledProductName
                  onClick={() => this.handleClickNotExtendedProduct(index)}
                >
                  {product.name}{product.badge && <Badge>{product.badge}</Badge>}
                </StyledProductName>
              </CSSTransition>

            )
        })}
      </TransitionGroup>
    )
  }
}

export default withTranslation('authentication')(RollingProducts);
