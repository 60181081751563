/**
 * Компонент Link - стилизованный тег "а" c аналогичными атрибутами
 */

import React from 'react'
import styled from 'styled-components'

const StyledLink = styled.a`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  /* syntax / on dark / value */

  color: #9bcbfa;
  mix-blend-mode: normal;
  text-decoration: none;
  cursor: pointer;

  :hover {
    text-decoration: none;
  }
`

function Link(props) {
  const { className, ...linkProps } = props
  return <StyledLink className={className} {...linkProps} />
}

export default Link
