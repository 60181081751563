import {Link as RouteLink} from 'react-router-dom'
import React from "react";
import Button from "./Button";
import { useTranslation } from 'react-i18next'

function BackToHomeButton(props) {

    const { t } = useTranslation('authentication');

    return (

        <RouteLink to="/" style={{color: '#fff', textDecoration: 'none'}} >
            <Button style={{width: '100%'}} >
                {t('back')}
            </Button>
        </RouteLink>

    );

}

export default BackToHomeButton;
