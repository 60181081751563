/**
 * IndicatorTag - компонент "Цветной индикатор + текст"
 *
 * Пропсы:
 *  indicatorColor - цвет индикатора
 *
 * Текст размещается как ребенок компонента
 */

import React from 'react'
import styled from 'styled-components'

const StyledTag = styled.span`
  display: inline-block;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 12px;
  padding: 2px 6px;
`

const StyledIndicator = styled.span`
  display: inline-block;
  height: 10px;
  width: 10px;

  margin-right: 6px;
  border-radius: 50%;

  background: ${({ indicatorColor }) => indicatorColor};
`

const StyledText = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;

  color: rgba(255, 255, 255, 0.87);
`

function IndicatorTag(props) {
  const { className, indicatorColor, children } = props
  return (
    <StyledTag className={className}>
      <StyledIndicator indicatorColor={indicatorColor} />
      <StyledText>{children}</StyledText>
    </StyledTag>
  )
}

export default IndicatorTag
