import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Link from '../../../../Components/Link.js'
import BackToHomeButton from "../../../../Components/BackToHomeButton";

const LinkIsInactiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const LinkIsInactiveTitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 121.7%;

  color: rgba(255, 255, 255, 0.87);

  margin-bottom: 8px;
`
const LinkIsInactiveDescription = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  color: rgba(255, 255, 255, 0.6);
  mix-blend-mode: normal;

  margin-bottom: 32px;
  white-space: break-spaces;
`

function LinkIsInactive(props) {

  const { t } = useTranslation('authentication');
  const ct = useTranslation('common').t;

  return (
    <LinkIsInactiveWrapper>
      <LinkIsInactiveTitle>{t('inactiveLink.title')}</LinkIsInactiveTitle>
      <LinkIsInactiveDescription>
        {t('inactiveLink.description')}
        <Link href="mailto:support@group-ib.com">
            {' ' + ct('email')}
        </Link>
      </LinkIsInactiveDescription>
        <BackToHomeButton type={'blue'} >{t('back')}</BackToHomeButton>
    </LinkIsInactiveWrapper>
  )
}

export default LinkIsInactive
