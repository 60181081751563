import React, { useState, useContext } from 'react'
import { Link as RouteLink, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import './styles.css'

import Textfield from '../../../../Components/Textfield.js'
import Button from '../../../../Components/Button.js'

import { api } from '../../../../Components/Api/index'
import AppContext from '../../../../Components/Context/AppContext'
import ErrorDescription from "../../../../Components/Error";
import { authRedirect } from '../../../../Components/Utils/authRedirect';
import BackToHomeButton from "../../../../Components/BackToHomeButton";

const LoginFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

const LoginFormTitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;

  color: #ffffff;

  margin-bottom: 8px;
`;

const LoginFormDescription = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  color: rgba(255, 255, 255, 0.6);
  mix-blend-mode: normal;

  margin-bottom: 24px;
`;

function SamlForm(props) {

    const [login, setLogin] = useState('');
    const [formAlert, setAlert] = useState(null);
    const [processing, setProcessing] = useState(false);

    const { t } = useTranslation('authentication');

    // обработчик события подтверждения формы
    async function handleSubmit(event) {
        event.preventDefault();

        if (!validate() || processing) {
            return;
        }

        setProcessing(true);
        const _login = login.trim();

        try {
            let response = await api.post(
                '/saml/check_user',
                {
                    email: _login
                },
                {
                    baseURL: ''
                }
            );

            // let response = {
            //     success: false,
            //     error: 'settings_not_found',
            //     url: '/test'
            // };

            if (response.success && response.success) {

                document.location.href = response.url;
                return true;

            } else {
                setAlert(response.error || 'unknown_error');
            }

            setProcessing(false);
        } catch (ex) {
            setProcessing(false);
        }

    }

    function validate() {
        let error = null;

        if (!login) {
            error = 'login_is_empty';
        }

        setAlert(error);

        return null === error;
    }

    return (
        <LoginFormWrapper onSubmit={handleSubmit}>
            <LoginFormTitle>{t('samlForm.title')}</LoginFormTitle>

            <Textfield
                id={"sso-auth-field-first"}
                error={formAlert}
                style={{ marginBottom: '12px' }}
                className="LoginForm__textfield"
                type="text"
                label={t('loginForm.email')}
                onChange={(event) => setLogin(event.target.value)}
                value={login}
                autoComplete="on"
            />

            {formAlert && <ErrorDescription style={{marginBottom: '10px'}} code={formAlert} />}

            <Button type="submit" style={{marginBottom: '16px'}} >{t('loginForm.signInButton')}</Button>
            <BackToHomeButton />
        </LoginFormWrapper>
    )
}

export default SamlForm
