import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Text } from '@gib-ui/core/Text';
import { Button } from '@gib-ui/core/Button';

import { TwoFaQrCode } from '../../../../../Components/TwoFaQrCode';
import { useSaveTwoFa } from '../../../../../hooks/useSaveTwoFa';
import { TwoFaResetPasswordSuccessfulActivation } from '../SuccessfulActivation/TwoFaResetPassword-SuccessfulActivation';

import { cnTwoFaResetPassword } from '../TwoFaResetPassword';

export const TwoFaResetPasswordQrForm = () => {
    const t = useTranslation('twoFa').t;
    const { token } = useParams();
    const [qrValue, setQrValue] = useState('');
    const { data, save, success, error, reset } = useSaveTwoFa(token);

    const handleChangeQr = (value) => {
        setQrValue(value);

        if (value.length === 6) {
            save(value);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        save(qrValue);
    }

    useEffect(() => {
        if (error) {
            setQrValue('');
        }
    }, [error]);

    return (
        <div>
            {
                success ? <TwoFaResetPasswordSuccessfulActivation /> : <form onSubmit={handleSubmit}>
                    <Text as="h2" type="h2" color="high" weight="bold" className={cnTwoFaResetPassword('Title')}>{t('twoFa')}</Text>
                    <Text type="body3" color="medium" style={{ display: 'block', marginBottom: '32px' }}>{t('titleCard')}</Text>
                    <TwoFaQrCode onChange={handleChangeQr} error={error} token={token} />
                    {/*<Button type="submit" view="filled-brand" width="max" disabled={qrValue.length < 6} style={{ marginBottom: '8px', marginTop: '32px' }}>{t('sendCode')}</Button>*/}
                    <Button as="a" href="/" view="outline" width="max">{t('cancel')}</Button>
                </form>
            }
        </div>
    )
}
