import React from 'react'
import styled from 'styled-components'

const StyledBlock = styled.div`
    margin-left: 8px;
    vertical-align: top;
    display: inline-block;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    /* identical to box height, or 127% */   
    color: rgba(0, 0, 0, 0.74);
    
    background: #FFFFFF;
    border-radius: 18px;
    padding: 2px 6px;
`

function Badge(props) {
    const { className, ...linkProps } = props;
    return <StyledBlock className={className} {...linkProps} />
}

export default Badge
