import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../Components/Api';

export const getErrorMsg = (error, t) => {
    switch (error) {
        case 'verification_code_is_empty':
            return t('emptyCode');
        case 'verification_code_is_invalid':
            return t('invalidCode');
        default: return 'unknown_answer';
    }
}

export const useSaveTwoFa = (token) => {
    const t = useTranslation('twoFa').t;
    const [data, setData] = useState({
        data: undefined,
        success: false,
        error: undefined
    });
    const save = async (value) => {
        try {

            let body = {
                code: value
            }

            if (token) {
                body.token = token;
            }

            const response = await api.post('/2fa/setup', body);
            if (response.success) {
                setData({
                    data: response,
                    success: true,
                    error: undefined
                })
            } else if (response.error) {
                setData({
                    data: response,
                    success: false,
                    error: {
                        error: response.error,
                        message: getErrorMsg(response.error, t)
                    }
                })
            }
        } catch (ex) {
            setData({
                data: undefined,
                success: false,
                error: {
                    error: ex,
                    message: ex.statusText
                }
            })
        }
    }
    const reset = () => {
        setData({
            data: undefined,
            success: false,
            error: undefined
        })
    }
    return {
        success: data.success,
        data: data.data,
        error: data.error,
        save,
        reset
    }
}
