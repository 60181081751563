import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import BackToHomeButton from "../../../../Components/BackToHomeButton";

const ChangePasswordFinishPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const ChangePasswordFinishPageTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 121.7%;

  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 8px;
`
const ChangePasswordFinishPageDescription = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  color: rgba(255, 255, 255, 0.6);
  mix-blend-mode: normal;
  margin-bottom: 24px;
`

function ChangePasswordFinishPage() {
  const { t } = useTranslation('authentication')
  return (
    <ChangePasswordFinishPageWrapper>
      <ChangePasswordFinishPageTitle>{t('passwordChanged.title')}</ChangePasswordFinishPageTitle>
      <ChangePasswordFinishPageDescription>{t('passwordChanged.description')}</ChangePasswordFinishPageDescription>
        <BackToHomeButton type={'blue'} >{t('back')}</BackToHomeButton>
    </ChangePasswordFinishPageWrapper>
  )
}

export default ChangePasswordFinishPage
