import React from 'react'
import moment from 'moment';

export default function Date(props) {

    if (!props.value) {
        return '-/-';
    }

    return(
        <div title={props.value} >
            {moment(props.value).format('YYYY-MM-DD HH:MM')}
        </div>
    );

}
