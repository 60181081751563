import React, { useState, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { CookiesProvider, useCookies } from 'react-cookie';
import { configureRootTheme } from '@gib-ui/core/Theme';
import { presets } from '@gib-ui/core/Theme/presets';
import Authorization from './Pages/Authorization/Authorization'
import Authorized from './Pages/Authorized/Authorized'
import Helm from './Pages/Helm/Index'
import AppContext from './Components/Context/AppContext'
import './App.css'
import i18n from './i18n'
// import { testProjects } from './test_data_projects';

configureRootTheme({ theme: presets.default });

function Root() {

    const [sessionParams, setSessionParams] = useState({
        csrfToken: window.GIB.csrfToken || '',
        isAuthenticated: window.GIB.isAuthenticated || false,
        companies: window.GIB.companies || [],
        projects: window.GIB.projects || {},
        user: window.GIB.user || '',
        username: window.GIB.username || '',
        defaultLang: window.GIB.defaultLang || '',
        langKey: window.GIB.langKey || '_lang',
        lang: window.GIB.lang || '',
        forbiddenProject: window.GIB.forbiddenProject || '',
        userIp: window.GIB.userIp || '',
        error: window.GIB.error || '',
        avatar: window.GIB.avatar || {},
        twoFaIsOn: window.GIB.twoFaIsOn || false
    });

    const [cookies, setCookie] = useCookies([sessionParams.langKey]);

    let defaultLang = cookies[sessionParams.langKey] || sessionParams.lang || 'en';

    if (window.location.origin.search(/.*\.ru$/) !== -1) {
        defaultLang = 'ru'
    }

    const [lang, setLang] = useState(defaultLang);
    i18n.changeLanguage(lang);

    function handleChangeLang(lang) {
        setLang(lang);
        setCookie(sessionParams.langKey, lang);
        i18n.changeLanguage(lang);
    }

    const authorization = <Authorization
        lang={lang}
        onChangeLang={handleChangeLang}
    />;

    const authorized = <Authorized
        lang={lang}
        onChangeLang={handleChangeLang}
    />;

    const helm = <Helm />

    return (
        <Router>
            <AppContext.Provider value={{ sessionParams, setSessionParams }} >
                <div className="App">
                    {
                        sessionParams.isAuthenticated

                            ?

                            <Switch>
                                <Route exact path='/change_password' >{authorization}</Route>
                                <Route exact path='/reset_password/:token' >{authorization}</Route>
                                <Route exact path='/forbidden/:project' >{authorization}</Route>
                                <Route exact path='/2fa/verification'>{authorization}</Route>
                                <Route exact path='/2fa/settings'>{authorized}</Route>

                                <Route exact path='/helm/:section'>{helm}</Route>
                                <Route exact path='/' >{authorized}</Route>

                            </Switch>

                            :

                            <Switch>
                                <Route exact path='/reset_password/:token' >{authorization}</Route>
                                <Route exact path='/recovery_password' >{authorization}</Route>
                                <Route exact path='/change_password' >{authorization}</Route>
                                <Route exact path='/saml_auth'>{authorization}</Route>
                                <Route exact path='/' >{authorization}</Route>
                            </Switch>
                    }
                </div>
            </AppContext.Provider>
        </Router>
    )
}

export default function App() {
    return (
        <Suspense fallback={'...loading'}>
            <CookiesProvider>
                <Root />
            </CookiesProvider>
        </Suspense>
    )
}
