const responseType = 'json';
const credentials = 'same-origin';

const defaults = {
    headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        'X-CSRF-Token': window.GIB.csrfToken
    },
    baseURL: '',
    transformResponse: data => {
        const { status } = data;
        if (status && status !== 200) {
            if (data.error === 'Unauthorized') {
                window.location.href = window.origin;
            } else {
                throw new Error(data.error);
            }
        }
        return data.data || data;
    },
    credentials,
    responseType
};

export default defaults;
