import React, {useState} from 'react'
import Textfield from "../../Components/Textfield";
import Button from "../../Components/Button";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const FormWrapper = styled.form`
  display: flex;
  flex-direction: row;
`;

export default function SearchForm(props) {

    const t = useTranslation('helm').t;
    const [searchValue, setSearchValue] = useState(props.q);

    const onSubmitHandle = (e) => {
        e.preventDefault();
        props.onSubmit(searchValue);
    }

    return(
        <FormWrapper style={{height: '50px'}} onSubmit={onSubmitHandle} >
            <Textfield
                view="dense"
                style={{ marginBottom: '12px', width: '500px' }}
                type="text"
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                autoComplete="on"
            />
            <Button onClick={onSubmitHandle} >{t('Search')}</Button>
        </FormWrapper>
    );

}
