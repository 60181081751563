/**
 * Select - компонент выбора значения из списка
 *
 * Пропсы:
 *
 *  default: string - значение селекта по умолчанию
 *  options: array - список опций-объектов (пример см. ниже)
 *    {
 *       id: any - id опции
 *       name: string - текст опции
 *    }
 *
 * disabled: boolean - выключение селекта
 *
 * Эвенты:
 *  onChange - возвращает опцию, если она выбрана
 *
 */

import React from 'react'
import classNames from 'classnames'
import './styles.css'

class Select extends React.Component {
  constructor(props) {
    super(props);
    // @defaultSelectText => Show default text in select
    // @showOptionList => Show / Hide List options
    // @options => List of options
    this.state = {
      defaultSelectText: '',
      showOptionList: false,
    }
  }

  componentDidMount() {
    // Add Event Listner to handle the click that happens outside
    // the Custom Select Container
    document.addEventListener('mousedown', this.handleClickOutside);
    this.setState({
      defaultSelectText: this.props.default,
    })
  }

  componentWillUnmount() {
    // Remove the event listner on component unmounting
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  // This method handles the click that happens outside the
  // select text and list area
  handleClickOutside = (e) => {
    if (
      !e.target.classList.contains('custom-select-option') &&
      !e.target.classList.contains('selected-text')
    ) {
      this.setState({
        showOptionList: false,
      })
    }
  };

  // This method handles the display of option list
  handleListDisplay = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState((prevState) => {
      return {
        showOptionList: !prevState.showOptionList,
      }
    })
  };

  // This method handles the setting of name in select text area
  // and list display on selection
  handleOptionClick = (option) => {
    this.setState({
      defaultSelectText: option.name,
      showOptionList: false,
    });

    this.props.onChange(option)
  };

  render() {
    const { options } = this.props;
    const { showOptionList, defaultSelectText } = this.state;

    const selectedTextClass = classNames('selected-text', {
      disabled: this.props.disabled,
      active: showOptionList,
    });

    return (
      <div className="custom-select-container" onClick={(e) => {
        e.stopPropagation();
      }}>
        <div onClick={this.handleListDisplay} className={selectedTextClass}>
          {defaultSelectText}
        </div>
        {showOptionList && (
            <div className="select-options-container" >
              <ul className="select-options">
                {options.map((option) => {
                  return (
                      <li
                          className="custom-select-option"
                          key={option.id}
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleOptionClick(option)
                          }}
                      >
                        {option.name}
                      </li>
                  )
                })}
              </ul>
            </div>
        )}
      </div>
    )
  }
}

export default Select
