import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { cn } from '@bem-react/classname';
import { Card } from '@gib-ui/core/Card';
import { Text } from '@gib-ui/core/Text';
import { Button } from '@gib-ui/core/Button';

import { TwoFaModal } from '../TwoFaModal';
import { TwoFaCardIcon } from './Icon/TwoFaCard-Icon';
import './TwoFaCard.css';

export const cnTwoFaCard = cn('TwoFaCard');

export const TwoFaCard = ({ setTwoFaCardVisible, reset, onProtectClick }) => {
    const at = useTranslation('twoFa').t;
    const history = useHistory();
    const [isOpenModal, setIsOpenModal] = useState(history.location.pathname === '/2fa/settings');

    const handleOpenModal = useCallback(() => {
        setIsOpenModal(true);
        history.push('/2fa/settings');
    }, [setIsOpenModal]);

    const handleCloseModal = useCallback(() => {
        setIsOpenModal(false);
        history.push('/');
    }, [setIsOpenModal]);

    const handleProtectClick = () => {
        if (onProtectClick) {
            onProtectClick();
        }
    }
    return (
        <Card className={cnTwoFaCard()}>
            <div className={cnTwoFaCard('Header')}>
                <TwoFaCardIcon />
            </div>
            <Text type="body4" color="high" className={cnTwoFaCard('Title')}>{at('titleCard')}</Text>
            <Text type="body3" color="medium">{at('subTitleCard')}</Text>
            <div className={cnTwoFaCard('Footer')}>
                {
                    !reset ? <Button view="outline" onClick={handleOpenModal}>{at('protectedBtn')}</Button> :
                        <Button view="filled-brand" onClick={handleProtectClick}>{at('protectedBtn')}</Button>
                }

            </div>
            <TwoFaModal isOpen={isOpenModal} onClose={handleCloseModal} setTwoFaCardVisible={setTwoFaCardVisible} />
        </Card>
    )
}
