import React from 'react'
import styled from 'styled-components'

const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 4px;
  background: #959a9f;
  position: absolute;
  left: 0;
  bottom: 0;
`

function ProgressBar(props) {
  const { percent } = props
  return (
    <ProgressBarWrapper>
      <div
        style={{
          width: `${percent}%`,
          position: 'absolute',
          content: '',
          background: ' #fbe51f',
          height: '100%',
        }}
      />
    </ProgressBarWrapper>
  )
}

export default ProgressBar
