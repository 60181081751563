import queryString from 'query-string';
import { checkRedirectUri } from './Projects'

export const authRedirect = (response) => {
    let projects = response.projects;
    console.log('auth');
    let query = queryString.parse(document.location.search);
    // Продолжаем авторизацию в проекте, если она была начата
    if (query.back || query.redirect_uri) {

        let back = query.redirect_uri || query.back;

        // Временный хак для TDS
        if (back.search(/soc.group-ib.com/) !== -1) {
            document.location.href = '/';
            return true;
        }

        if (checkRedirectUri(back, projects.active)) {
            document.location.href = back;
            return true;
        }
    }

    // Если у пользователя доступен только один проект и у проекта только один url, то сразу редиректим
    // пользователя туда
    if (Object.keys(projects.active).length === 1) {

        let returnNow = false;

        Object.keys(projects.active).forEach(code => {

            if (projects.active[code].url.length === 1) {
                document.location.href = projects.active[code].url[0];
                returnNow = true;
            }

        });

        if (returnNow) {
            return true;
        }

    }
}