import React, {useCallback, useContext, useEffect, useState} from 'react';
import { Table, TableRow, TableBody, TableHead, TableHeadCell, TableBodyCell } from '@gib-ui/core/Table';
import { Switch } from '@gib-ui/core/Switch';
import Button from '../../Components/Button.js'
import { api } from '../../Components/Api/index';
import { useTranslation } from 'react-i18next';
import AppContext from "../../Components/Context/AppContext";
import SearchForm from './SearchForm';
import './styles.css';
import {Link as RouteLink, useHistory, useRouteMatch} from 'react-router-dom';
import Date from '../../Components/Value/Date';
import queryString from 'query-string';


function getStaticUserList() {

    return {
        "rows": [
            {
                "email": "179@localhost.ru",
                "name": "Администратор fewfwАдминистратор fewfwАдминистратор fewfw",
                "avatar": null,
                "phone": null,
                "seq_update": 5525,
                "meta": {
                    "ci3": []
                },
                "createdAt": "2021-02-18T15:15:08+03:00",
                "updatedAt": "2021-05-13T20:55:10+03:00",
                "lockedAt": "2021-05-13T20:55:10+03:00",
                "access": false,
                "subscriptions": [],
                "projectRelations": [],
                "companies": [],
                "twoFaIsOn": true
            },
            {
                "email": "fewfwefwe@mail.ru",
                "name": "Test Test",
                "avatar": {
                    "data": "iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAABJtJREFUeJzt3c9rHAUYxvHnfWdtEpukNhjSX8m8cy+iFqyIkJMgip6MCj2If0n/jlJKUPHQ1IP1UHoL3sWqFG/SY7GBtrGRxGTnfT0k8aBpRrLZzG7e5wPJZfbHy77fhNk57AJERERERERElIW0PQD115t3r09hs7auFqaOEhIWIRbipqElAxhyl7679rIWhWldly5qiDARsQiUEVGqyuR+92cAA+7VOzemO92uqRYGd4OIeUSpIhZAKcB4L4/PAFp2+fb1GRS11V6YCkpEbC/Z3VS1BPBiP5+fAfRThLx+99qZYqtjKnXpoQYJExeDujl0ToGxNkdkAL2IkLdu3zi72alNXE0kDC7mGqW6mMPnVHW07TH3wwD2c/WqvnH5/DnphtWqJo5SJAw7Z9HiOgvFSNtj9oIBANuLvjR7JSQMERYipoES4rOAnmh7vH469gH89ukHMydQ24X65A9y61a9123mlxdH19e660c92yDotD1Ar3YX7KEWcANgCDGBmytKhY85BI+ACQBrLY87cAY+gP+7YEhAdv+hCQAotL2xh0brATxYeO8MxK1AYSFhATHATUS3z6K54L7qewDNC5YxoEAAwD8r1p3fXHG/9RwAFzzcGgPggo+3DhecW0cLecgF58UNJ8cAkmMAyTGA5BhAcgwgOQaQHANIjgEkxwCSYwDJMYDkGEByDCA5BpAcA0iOASTHAJJjAMkxgOQYQHIMIDkGkBwDSI4BJMcAkmMAyTGA5BhAcgwgOQaQHANIjgEkxwCSYwDJyYPP5gf648wPS/XF9xv7HZ9fXkzxOhARERERUXYyvXS/py8eHBYrH1/c99tCfl9aSPE6/JuMf/VztD3EURgbKSaeF0Eszo+ujY+n/M4gXglMjgEkxwCSYwDJMYDkGEByDCA5BpAcA0iOASTHAJJjAMkxgOQYQHIMIDkGkBwDSI4BJMcAkmMAyTGA5BhAcgwgOQaQHANIjgEkxwCSYwDJMYDkGEByDCA5BpAcA0iOASTHAJLrFF0/vfUCTL2wkDBxt1AxAcxdTBWn2h6S+qez+vlrTwH8tPPzH6cW773EQI6vTtMNDh5IVO5qqpg87KHp8DQG0KQxkK9/Ob3VdVMNi1ATgYW7icIYSPt6DqDJ6pVXngB4AuDeXscZSLv6HkCTAwcCVA4tGUhvWg+gSVMgkzfvT3U3a1MPC4VJiIXAxN1cYQqdONqJh8vAB9Dkj08uPgbwGMCPex3fDWQFv6b8KNgm0vYAgyAC8uyb999WD3MVkwhzSKXi5q6zqsP/h/I8DKBBLC0U6/rnhW6t2+chQBXhJiIWiCqA8wot2p7zoBhAj2J5vvP00eRsp6jNQyqNsJDd6yBiUD+n0IG95M4A+iyWFk6sxsZcUfjOuxepQsTEYa5ucD2r2t4eGEDL/M67I89WpSw6hXl4JSLmcFPXytVNoTP9fH4GMOD85kdja8VGqVFX7mqiYYBUEWEiYgCme3l8BjDkHn75zsnxsVFzr00hVWhsXwuBVyJqAKb2uz8DOOZWvv1wYvSvLXNotX01decEVcTU3dqej4iIiIiIiIiOzt9hXr3xXWQ4PwAAAABJRU5ErkJggg==",
                    "mime": "image/png"
                },
                "phone": "",
                "seq_update": 6314,
                "meta": {
                    "ci3": [],
                    "huntbox-ru": []
                },
                "createdAt": "2021-02-03T21:27:11+03:00",
                "updatedAt": "2021-08-05T20:05:19+03:00",
                "access": false,
                "subscriptions": [
                    {
                        "companyId": 101,
                        "project": {
                            "id": 10,
                            "name": "Intelligence 3"
                        },
                        "isActive": true,
                        "dateStart": "2015-11-04",
                        "dateEnd": "2025-02-01",
                        "accountManager": "116@localhost",
                        "createdAt": "2021-04-05T16:53:45+03:00",
                        "updatedAt": "2021-11-16T22:16:04+03:00"
                    },
                    {
                        "companyId": 101,
                        "project": {
                            "id": 2,
                            "name": "TDS"
                        },
                        "isActive": true,
                        "dateStart": "2015-11-03",
                        "dateEnd": "2101-01-31",
                        "accountManager": null,
                        "createdAt": "2021-06-07T15:42:55+03:00",
                        "updatedAt": "2021-06-08T18:44:06+03:00"
                    },
                    {
                        "companyId": 101,
                        "project": {
                            "id": 1009,
                            "name": "Business Email Protection"
                        },
                        "isActive": true,
                        "dateStart": "2015-11-03",
                        "dateEnd": "2101-01-31",
                        "accountManager": null,
                        "createdAt": "2021-06-07T15:54:42+03:00",
                        "updatedAt": "2021-06-08T18:44:06+03:00"
                    },
                    {
                        "companyId": 102,
                        "project": {
                            "id": 10,
                            "name": "Intelligence 3"
                        },
                        "isActive": true,
                        "dateStart": "2015-11-03",
                        "dateEnd": "2199-01-01",
                        "accountManager": null,
                        "createdAt": "2021-04-06T21:42:06+03:00",
                        "updatedAt": "2021-09-02T11:50:49+03:00"
                    },
                    {
                        "companyId": 101,
                        "project": {
                            "id": 16,
                            "name": "Attack Surface Management"
                        },
                        "isActive": true,
                        "dateStart": "2021-11-25",
                        "dateEnd": "2033-11-25",
                        "accountManager": null,
                        "createdAt": "2021-11-16T21:37:01+03:00",
                        "updatedAt": "2021-11-25T22:41:21+03:00"
                    }
                ],
                "companies": [
                    {
                        "id": 101,
                        "name": "The Floow",
                        "modules": [
                            "huntbox-module-atmosphere"
                        ],
                        "logo": {
                            "data": "iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAABJtJREFUeJzt3c9rHAUYxvHnfWdtEpukNhjSX8m8cy+iFqyIkJMgip6MCj2If0n/jlJKUPHQ1IP1UHoL3sWqFG/SY7GBtrGRxGTnfT0k8aBpRrLZzG7e5wPJZfbHy77fhNk57AJERERERERElIW0PQD115t3r09hs7auFqaOEhIWIRbipqElAxhyl7679rIWhWldly5qiDARsQiUEVGqyuR+92cAA+7VOzemO92uqRYGd4OIeUSpIhZAKcB4L4/PAFp2+fb1GRS11V6YCkpEbC/Z3VS1BPBiP5+fAfRThLx+99qZYqtjKnXpoQYJExeDujl0ToGxNkdkAL2IkLdu3zi72alNXE0kDC7mGqW6mMPnVHW07TH3wwD2c/WqvnH5/DnphtWqJo5SJAw7Z9HiOgvFSNtj9oIBANuLvjR7JSQMERYipoES4rOAnmh7vH469gH89ukHMydQ24X65A9y61a9123mlxdH19e660c92yDotD1Ar3YX7KEWcANgCDGBmytKhY85BI+ACQBrLY87cAY+gP+7YEhAdv+hCQAotL2xh0brATxYeO8MxK1AYSFhATHATUS3z6K54L7qewDNC5YxoEAAwD8r1p3fXHG/9RwAFzzcGgPggo+3DhecW0cLecgF58UNJ8cAkmMAyTGA5BhAcgwgOQaQHANIjgEkxwCSYwDJMYDkGEByDCA5BpAcA0iOASTHAJJjAMkxgOQYQHIMIDkGkBwDSI4BJMcAkmMAyTGA5BhAcgwgOQaQHANIjgEkxwCSYwDJyYPP5gf648wPS/XF9xv7HZ9fXkzxOhARERERUXYyvXS/py8eHBYrH1/c99tCfl9aSPE6/JuMf/VztD3EURgbKSaeF0Eszo+ujY+n/M4gXglMjgEkxwCSYwDJMYDkGEByDCA5BpAcA0iOASTHAJJjAMkxgOQYQHIMIDkGkBwDSI4BJMcAkmMAyTGA5BhAcgwgOQaQHANIjgEkxwCSYwDJMYDkGEByDCA5BpAcA0iOASTHAJLrFF0/vfUCTL2wkDBxt1AxAcxdTBWn2h6S+qez+vlrTwH8tPPzH6cW773EQI6vTtMNDh5IVO5qqpg87KHp8DQG0KQxkK9/Ob3VdVMNi1ATgYW7icIYSPt6DqDJ6pVXngB4AuDeXscZSLv6HkCTAwcCVA4tGUhvWg+gSVMgkzfvT3U3a1MPC4VJiIXAxN1cYQqdONqJh8vAB9Dkj08uPgbwGMCPex3fDWQFv6b8KNgm0vYAgyAC8uyb999WD3MVkwhzSKXi5q6zqsP/h/I8DKBBLC0U6/rnhW6t2+chQBXhJiIWiCqA8wot2p7zoBhAj2J5vvP00eRsp6jNQyqNsJDd6yBiUD+n0IG95M4A+iyWFk6sxsZcUfjOuxepQsTEYa5ucD2r2t4eGEDL/M67I89WpSw6hXl4JSLmcFPXytVNoTP9fH4GMOD85kdja8VGqVFX7mqiYYBUEWEiYgCme3l8BjDkHn75zsnxsVFzr00hVWhsXwuBVyJqAKb2uz8DOOZWvv1wYvSvLXNotX01decEVcTU3dqej4iIiIiIiIiOzt9hXr3xXWQ4PwAAAABJRU5ErkJggg==",
                            "mime": "image/png"
                        }
                    },
                    {
                        "id": 102,
                        "name": "AGM Group",
                        "modules": [],
                        "logo": null
                    }
                ],
                "projectRelations": [
                    {
                        "id": 6898,
                        "projectId": 1,
                        "projectName": "Intelligence",
                        "access": 1,
                        "createdAt": "2021-02-18T15:15:09+03:00",
                        "updatedAt": "2021-02-18T15:15:09+03:00"
                    },
                    {
                        "id": 6899,
                        "projectId": 3,
                        "projectName": "SB",
                        "access": 1,
                        "createdAt": "2021-02-18T15:50:40+03:00",
                        "updatedAt": "2021-02-18T15:50:40+03:00"
                    },
                    {
                        "id": 6900,
                        "projectId": 10,
                        "projectName": "Intelligence 3",
                        "access": 1,
                        "createdAt": "2021-02-18T15:51:26+03:00",
                        "updatedAt": "2021-02-18T15:51:26+03:00"
                    },
                    {
                        "id": 6924,
                        "projectId": 100,
                        "projectName": "Dark Matter",
                        "access": 1,
                        "createdAt": "2021-03-23T16:58:06+03:00",
                        "updatedAt": "2021-03-23T16:58:06+03:00"
                    }
                ],
                "twoFaIsOn": false
            }
        ]
    };

}

function getUniqueCompanyList(users) {

    let companies = {};

    users.forEach((user) => {
        user.companies.map((company) => companies[company.id] = company.name);
    });

    return companies;
}

async function fetchUserList(page, searchValue) {

    return await api.get('/api/web/helm/user', {
        params: {
            page: page,
            q: searchValue,
            limit: 30
        }
    })

}

function UserList(props) {

    const appContext = useContext(AppContext);

    const history = useHistory();
    const route = useRouteMatch();
    const query = queryString.parse(document.location.search);

    const t = useTranslation('helm').t;

    const [searchValue, setSearchValue] = useState(query.q);
    const [page, setPage] = useState(1);

    const [usersCount, setUsersCount] = useState(0);
    const [users, setUsers] = useState([]);
    const [companies, setCompanies] = useState({});
    const [processing, setProcessing] = useState(false);

    const fetch = useCallback((searchValue) => {
        // setUsers(getStaticUserList().rows);
        // setCompanies(getUniqueCompanyList(getStaticUserList().rows));
        fetchUserList(page, searchValue).then((data) => {
            setUsers(data.rows);
            setCompanies(getUniqueCompanyList(data.rows));
            setUsersCount(data.count || 0);
        });
    }, [page, searchValue]);

    const refetch = () => {
        fetch(searchValue);
    }

    useEffect(() => {
        fetch(searchValue);

        const query = queryString.stringify({
            q: searchValue
        });

        history.push(route.path + '?' + query);
    }, [page, searchValue]);

    const handleSubmit = (searchValue) => {
        setSearchValue(searchValue);
    }

    return (
        <div>
            <SearchForm q={searchValue} onSubmit={handleSubmit} />
            <Table view="card" className="company-table" >
                <TableHead>
                    <TableRow>
                        <TableHeadCell style={{whiteSpace: 'nowrap'}} >{t('Email')}</TableHeadCell>
                        <TableHeadCell style={{width: '200px'}} >{t('Name')}</TableHeadCell>
                        <TableHeadCell style={{textAlign: 'center'}} >{t('2FA')}</TableHeadCell>
                        <TableHeadCell>{t('Info')}</TableHeadCell>
                        <TableHeadCell>{t('Subscriptions')}</TableHeadCell>
                        <TableHeadCell>{t('Actions')}</TableHeadCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user, index) => (
                        <UserRow key={user.email} user={user} companies={companies} refetch={refetch} />
                    ))}
                </TableBody>
            </Table>
        </div>
    );

}

async function turn2faOff(email) {

    return await api.post(`/api/web/helm/user/${email}/turn_two_fa_off`);

}

async function blockUser(email) {

    return await api.post(`/api/web/helm/user/${email}/block`);

}

async function unblockUser(email) {

    return await api.post(`/api/web/helm/user/${email}/unblock`);

}

function UserRow(props) {

    let user = props.user;
    let companies = props.companies;

    const t = useTranslation('helm').t;

    const [twoFaIsOn, setTwoFaiSOn] = useState(user.twoFaIsOn)

    const twoFaIsOnSwitchHandler = () => {

        const confirmation = window.confirm("Are you sure?");

        if (confirmation) {
            turn2faOff(user.email).then((data) => {

                if (data.success && data.success) {
                    setTwoFaiSOn(!twoFaIsOn);
                } else {
                    alert('Something went wrong, please try again later.');
                }

            }).catch(e => {
                alert('Something went wrong, please try again later.');
            });
        }

    }

    const blockUserHandler = () => {

        const confirmation = window.confirm("Are you sure?");

        if (confirmation) {
            blockUser(user.email).then((data) => {

                if (data.success && data.success) {
                    props.refetch();
                    alert('Good job!');
                } else {
                    alert('Something went wrong, please try again later.');
                }

            }).catch(e => {
                alert('Something went wrong, please try again later.');
            });
        }

    }

    const unblockUserHandler = () => {

        const confirmation = window.confirm("Are you sure?");

        if (confirmation) {
            unblockUser(user.email).then((data) => {

                if (data.success && data.success) {
                    props.refetch();
                    alert('Good job!');
                } else {
                    alert('Something went wrong, please try again later.');
                }

            }).catch(e => {
                alert('Something went wrong, please try again later.');
            });
        }

    }

    return (
        <TableRow>
            <TableBodyCell>{user.email}</TableBodyCell>
            <TableBodyCell>{user.name}</TableBodyCell>
            <TableBodyCell>
                <Switch
                    disabled={!twoFaIsOn}
                    onChange={twoFaIsOnSwitchHandler}
                    checked={twoFaIsOn}
                />
            </TableBodyCell>

            <TableBodyCell>
                <div style={{display: 'flex'}} ><span className="field-name" >{t('Created')}:</span> <Date value={user.createdAt} /></div>
                <div style={{display: 'flex'}} ><span className="field-name" >{t('Updated')}:</span> <Date value={user.updatedAt} /></div>
                <div style={{display: 'flex'}} ><span className="field-name" >{t('Locked')}:</span> {user.lockedAt ? <Date value={user.lockedAt} /> : '-/-'} </div>
            </TableBodyCell>

            <TableBodyCell>

                {
                    (user.subscriptions.length > 0 || user.projectRelations.length > 0) &&
                    <Table className="internal-table" >

                        <TableHead>
                            <TableRow>
                                <TableHeadCell style={{width: '200px'}} >{t('Company')}</TableHeadCell>
                                <TableHeadCell style={{width: '200px'}} >{t('Project')}</TableHeadCell>
                                <TableHeadCell style={{textAlign: 'center', width: '50px'}} >{t('Is active')}</TableHeadCell>
                                <TableHeadCell style={{width: '150px'}} >{t('Period')}</TableHeadCell>
                                <TableHeadCell style={{width: '250px'}} >{t('Info')}</TableHeadCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {
                                user.subscriptions.map((subscription, index) => (
                                    <TableRow key={`subscription-${index}`}>
                                        <TableBodyCell>
                                            <div>
                                                <RouteLink style={{color: '#8dcafe'}} to={`/helm/company?q=${companies[subscription.companyId]}`} >[{subscription.companyId}] {companies[subscription.companyId] || '-/-'}</RouteLink>
                                            </div>
                                        </TableBodyCell>
                                        <TableBodyCell>[{subscription.project.id}] {subscription.project.name}</TableBodyCell>
                                        <TableBodyCell style={{textAlign: 'center'}} >{subscription.isActive ? 'yes' : 'no'}</TableBodyCell>
                                        <TableBodyCell>{subscription.dateStart} - {subscription.dateEnd}</TableBodyCell>
                                        <TableBodyCell>
                                            <div style={{display: 'flex'}} ><span className="field-name" >{t('Manager')}:</span> {subscription.accountManager || '-/-'}</div>
                                            <div style={{display: 'flex'}} ><span className="field-name" >{t('Created')}:</span> <Date value={subscription.createdAt} /></div>
                                            <div  style={{display: 'flex'}}  ><span className="field-name" >{t('Updated')}:</span> <Date value={subscription.updatedAt} /> </div>
                                        </TableBodyCell>
                                    </TableRow>
                                ))
                            }

                            {
                                (user.subscriptions.length > 0 && user.projectRelations.length > 0) &&
                                <TableRow key={`subscription-hr`} style={{height: '0px'}} >
                                    <TableBodyCell colSpan="5" ><hr/></TableBodyCell>
                                </TableRow>
                            }

                            {
                                user.projectRelations.map((relation, index) => (
                                    <TableRow key={`rel-${index}`}>
                                        <TableBodyCell/>
                                        <TableBodyCell>[{relation.projectId}] {relation.projectName}</TableBodyCell>
                                        <TableBodyCell style={{textAlign: 'center'}} >{relation.access ? 'yes' : 'no'}</TableBodyCell>
                                        <TableBodyCell/>
                                        <TableBodyCell>
                                            <div style={{display: 'flex'}} ><span className="field-name" >{t('Created')}:</span> <Date value={relation.createdAt} /></div>
                                            <div  style={{display: 'flex'}}  ><span className="field-name" >{t('Updated')}:</span> <Date value={relation.updatedAt} /> </div>
                                        </TableBodyCell>
                                    </TableRow>
                                ))
                            }

                        </TableBody>

                    </Table>
                }

            </TableBodyCell>

            <TableBodyCell>

                {
                    !user.lockedAt

                    ?
                        <Button onClick={blockUserHandler} >Block</Button>
                    :
                        <Button onClick={unblockUserHandler} >Unblock</Button>
                }


            </TableBodyCell>

        </TableRow>
    );

}

export default UserList;
