import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    lang: 'en',
    debug: true,
    resources: {
      en: {
        authentication: {
          tryDemo: 'Try demo',
          runTest: 'Run test',
          tryDemoError: {
            title: 'Something went wrong',
            text: 'Please try again later'
          },
          tryDemoSuccessMessage: {
            atmosphere: {
              title: 'Your request has been sent',
              text: 'Your cloud tenant will be provisioned as soon as possible. You\'ll be notified once everything is set up.'
            }
          },
          selectCompany: 'Select company',
          selectLabel: 'Please select your company name',
          companyName: 'Company name',
          cancel: 'Cancel',
          expired: 'Expired',
          available: 'Available next',
          availableTill: 'Available till',
          days: 'days',
          trialPeriod: 'Trial period',
          products: {
            title: 'Products',

            name: {
              SBSP: 'Fraud Protection'
            },

            description: {
              main: `Group-IB’s security ecosystem provides comprehensive protection for your IT infrastructure based on our unique cyber intelligence, in-depth attack analysis, and effective incident response.`,

              TI: `Actionable Threat Intelligence from the World's Top Cybercrime Experts.`,
              TDS: `The single solution for IT and OT environments that detects unknown threats and targeted attacks; hunts for threats both within and beyond the protected perimeter; and helps investigate and respond to cybersecurity incidents.`,
              TDS2: 'AI-driven digital risk identification and mitigation platform.',
              Atmosphere: 'Block, detonate and hunt for the most advanced email threats with patented email security technology.',
              SBSP: `Protecting digital identity and preventing fraud in real time.`,
              brandProtection: `AI-driven digital risk identification and mitigation platform.`,
              SU: `Intelligence driven Attack Surface Management`,
              trebuchet: `Tool for testing email and network protection against targeted attacks`,
              attributionIntelligence: `Attribution-based Threat Intelligence for analyzing and managing adversaries before they affect your business.`,
            },
            tryDemo: 'Try demo',

            demoLinks: {
              ti: 'https://www.group-ib.com/intelligence.html',
              tds: 'https://www.group-ib.com/products/managed-xdr/',
              sb: 'https://www.group-ib.com/products/fraud-protection/',
              bp: 'https://www.group-ib.com/products/digital-risk-protection/',
              rt: '',
              ai: '',
            }
          },

          footerDescription: ` is the leading provider of high-fidelity threat 
            intelligence and anti-fraud solutions.`,
          footerName: 'Group-IB',

          loginForm: {
            title: 'Sign in',
            description:
              'Please sign in using your organization SSO credentials.',
            login: 'Login',
            email: 'Email',
            password: 'Password',
            rememberMe: 'Keep me signed in',
            forgotPassword: 'Forgot password?',
            signInButton: 'Sign in',
            signInWithSaml: 'Sign in with SAML',
          },

          samlForm: {
            title: 'Sign in with SAML',
          },

          passwordRecovery: {
            title: 'Password recovery',
            description: `Enter your email address below, and we'll email 
              instructions for setting a new one`,
            yourEmail: 'Your e-mail',
          },

          passwordRecentSent: {
            title: 'Password reset email sent',
            description: {
              partOne: `We've emailed you instructions for setting your password, 
                if an account exists with the email you entered. You should receive them 
                shortly.`,
              partTwo: `If you don't receive an email, please make sure you've entered the 
                address you registered with, and check your spam folder.`,
            },
          },

          changePassword: {
            title: 'Change password',
            description: `Password must contain at least eight characters, at 
              least one number, uppercase and lowercase letters and a special 
              character.`,
            oldPassword: 'Old password',
            newPassword: 'New password',
            newPasswordAgain: 'New password again',
          },

          passwordChanged: {
            title: 'Password updated',
            description: `You password has been changed successfully.`,
            backToSignIn: 'Back to sign in'
          },

          inactiveLink: {
            title: 'The link is inactive',
            description: `Please contact your Group-IB manager or submit a request to `,
          },

          wrongIp: {
            title: 'Your IP address is not in our whitelist',
            description: {
              partOne: `Sorry, your access is restricted as your IP address of `,
              partTwo: ` is not in our whitelist`,
              contact: `Please contact your Group-IB manager or submit a request to `,
            },
          },

          resetPassword: 'Reset password',
          back: 'Back to sign in',
        },

        authorized: {
          hello: 'Hello, ',
          choseProduct: 'Please choose a product to work with.',
          logout: 'Logout',
          topics: {
            title: 'Topics that may interest you:',
            changePassword: 'How do I change or reset my SSO password?',
            settings: 'Your account settings.',
            rights: 'Product connection and access rights.',
          },
          active: 'Active products',
          allProducts: 'Products',
          footer: {
            support: '24/7 support',
            description:
              ' is global leader in attribution-based threat intelligence, best-in-class threat hunting, fraud prevention, and cybercrime investigations.',
            name: 'Group-IB'
          },
        },
        twoFa: {
          titleCard: 'Protect your account with two-factor authentication',
          subTitleCard: 'Each time you log into your Group-IB SSO account, you will need to enter a code to verify',
          protectedBtn: 'Protect account',
          modalTitle: 'Account protection with two-factor authentication',
          cancel: 'Cancel',
          sendCode: 'Send code',
          next: 'Next',
          code: 'Code',
          back: 'Back',
          twoFa: 'Two-factor authentication',
          remember_me: 'Remember me',
          activatedMsg: 'Two-factor authentication activated',
          emptyCode: 'Verification code is empty',
          invalidCode: 'Verification code is invalid',
          qrDescr: 'Scan the QR code with two-step verification apps <1>(Google Authenticator or Duo Authentication)</1> and enter the code you received'
        },

        common: {
          phone: '+65 3159-4398',
          email: `support@group-ib.com`,
          gibLink: 'https://group-ib.com/',
        },

        errors: {

          user_not_found: 'User has not been found',
          passwords_not_equal: 'Passwords are not equal',
          passwords_is_too_short: 'The password must have at least 8 characters',
          password_is_empty: 'Please, set password',
          old_password_is_empty: 'Please, set old password',
          new_password_is_empty: 'Please, set new password',
          repeat_password_is_empty: 'Please set new password again',
          token_expired: 'Token has been expired',
          unknown_error: 'Unknown error',
          password_is_too_popular: 'Password is in the top passwords list. Please, make up a new one.',
          settings_not_found: 'Your account is not configured to use SAML SSO. Please sign in using your login and password or contact your manager in Group-IB.',
          old_password_invalid: 'Old password is invalid',
          used_password: 'You already used this password before',
          email_invalid: 'Email is invalid',
          password_invalid: 'Password is invalid',
          password_expired: 'Password is expired. Please reset',
          user_locked: 'Your account is locked. Please, contact system administrator',
          login_or_password_invalid: 'Email or password is invalid',
          login_is_empty: 'Please, set your login',
          reset_password_token_not_active: 'The Link is inactive',
          password_striction: 'Password must contain at least eight characters, at least one number, uppercase and lowercase letters and a special character',
          need_redirect_to_another_region: 'Attention! You will be automatically redirected to the new domain sso.facct.ru'

        },

        helm: {

        }

      },
      ru: {
        authentication: {
          tryDemo: 'Попробовать демо',
          runTest: 'Запустить тест',
          tryDemoError: {
            title: 'Что-то пошло не так',
            text: 'Пожалуйста, попробуйте позже.'
          },
          tryDemoSuccessMessage: {
            atmosphere: {
              title: 'Ваш запрос был отправлен',
              text: 'Сервис будет активирован в ближайшее время. Вы получите уведомление как только все будет готово.'
            }
          },
          selectCompany: 'Выбор компании',
          selectLabel: 'Пожалуйста выберите вашу компанию',
          companyName: 'Имя компании',
          cancel: 'Отменить',
          expired: 'Истекла',
          trialPeriod: 'Пробный период',
          available: 'Доступна на',
          availableTill: 'Доступен до',
          days: 'дней',
          January: 'Января',
          February: 'Февраля',
          March: 'Марта',
          April: 'Апреля',
          May: 'Мая',
          June: 'Июня',
          July: 'Июля',
          August: 'Августа',
          September: 'Сентября',
          October: 'Октября',
          November: 'Ноября',
          December: 'Декабря',
          products: {
            title: 'Продукты',

            name: {
              SBSP: 'Fraud Protection'
            },

            description: {
              main: `Экосистема решений для мониторинга, выявления и предотвращения 
                киберугроз, основанная на 17-летнем опыте команды F.A.C.C.T, глубоком 
                анализе целевых атак и самых актуальных разведданных из мира 
                киберпреступности.`,

              TI: `Киберразведка по подписке – мониторинг, анализ и прогнозирование угроз для компании, ее партнеров и клиентов.`,

              TDS: `Комплексное решение для защиты от сложных киберугроз в корпоративных и технологических сетях, основанное на технологиях слежения за киберпреступниками, их инструментами и инфраструктурой.`,
              TDS2: 'От предотвращения онлайн-мошенничества в режиме реального времени к идентификации злоумышленника',
              Atmosphere: 'Облачная защита электронной почты от целевых атак, детонация полезных нагрузок и атрибуция угроз.',
              SBSP: `Проактивная защита цифровой личности и предотвращения мошенничества в режиме реального времени.`,
              brandProtection: `Выявление и устранение цифровых рисков на основе искусственного интеллекта.`,
              SU: `Управление поверхностью атаки по данным киберразведки`,
              trebuchet: `Инструмент для оценки защищенности почты и сети от сложных целевых угроз`,
              attributionIntelligence: `Киберразведка и атрибуция угроз для исследования и управления атакующими до того, как они нанесут ущерб вашему бизнесу.`,
            },
            tryDemo: 'Попробовать демо',

            demoLinks: {
              ti: 'https://www.facct.ru/intelligence.html',
              tds: 'https://www.facct.ru/products/managed-xdr/',
              sb: 'https://www.facct.ru/products/fraud-protection/',
              bp: 'https://www.facct.ru/products/digital-risk-protection/',
              rt: '',
              ai: '',
            }
          },

          footerDescription: ` является ведущим поставщиком надежных решений для 
            анализа угроз и защиты от мошенничества.`,
          footerName: 'F.A.C.C.T',

          loginForm: {
            title: 'Вход',
            description:
              'Войдите в систему, используя учетные данные SSO вашей организации.',
            login: 'Логин',
            email: 'Адрес эл. почты',
            password: 'Пароль',
            rememberMe: 'Оставаться в системе',
            forgotPassword: 'Забыли пароль?',
            signInButton: 'Войти',
            signInWithSaml: 'Войти через SAML',
          },

          samlForm: {
            title: 'Войти через SAML',
          },

          passwordRecovery: {
            title: 'Восстановление пароля',
            description: `Введите свой адрес электронной почты ниже, 
              и мы отправим вам инструкции по восстановлению пароля.`,
            yourEmail: 'Ваш e-mail',
          },

          passwordRecentSent: {
            title: 'Письмо для сброса пароля отправлено',
            description: {
              partOne: `Инструкции по сбросу пароля отправлены по электронной почте. 
                Вы получите их в ближайшее время, если учетная запись с 
                указанным вами адресом электронной почты существует.`,

              partTwo: `Если вы не получили электронное письмо, убедитесь, что вы 
                ввели адрес, по которому вы зарегистрировались, и проверьте папку 
                со спамом.`,
            },
          },

          changePassword: {
            title: 'Сброс пароля',
            description: `Пароль должен содержать не менее восьми символов, хотя бы 
              одну цифру, прописные и строчные буквы и специальный символ.`,
            oldPassword: 'Старый пароль',
            newPassword: 'Новый пароль',
            newPasswordAgain: 'Повторите пароль',
          },

          passwordChanged: {
            title: 'Пароль изменен',
            description: `Ваш пароль был успешно изменен.`,
            backToSignIn: 'Назад'
          },

          inactiveLink: {
            title: 'Ссылка недействительна',
            description: `Пожалуйста, свяжитесь с вашим менеджером F.A.C.C.T или отправьте запрос на `,
          },

          wrongIp: {
            title: 'Ваш IP-адрес отсутствует в нашем белом списке',
            description: {
              partOne: `К сожалению, ваш доступ ограничен, так как ваш IP-адрес `,
              partTwo: ` не находится в нашем белом списке`,
              contact: `Пожалуйста, свяжитесь с вашим менеджером F.A.C.C.T или отправьте запрос на `,
            },
          },

          resetPassword: 'Сбросить пароль',
          back: 'Назад',
        },

        authorized: {
          hello: 'Здравствуйте, ',
          choseProduct: 'Выберите продукт, с которым хотите работать.',
          logout: 'Выйти',
          topics: {
            title: 'Темы, которые могут вас заинтересовать:',
            changePassword: 'Как мне изменить или сбросить пароль SSO?',
            settings: 'Ваши настройки аккаунта.',
            rights: 'Подключение продукта и права доступа.',
          },
          active: 'Активные продукты',
          allProducts: 'Продукты',
          footer: {
            support: 'Поддержка 24/7',
            description:
              ' является ведущим поставщиком надежных решений для анализа угроз и защиты от мошенничества.',
            name: 'F.A.C.C.T'
          },
        },

        twoFa: {
          titleCard: 'Защитите свою учетную запись с помощью двухфакторной аутентификации',
          subTitleCard: 'Каждый раз, когда вы входите в свою учетную запись SSO F.A.C.C.T, вам нужно будет вводить код для подтверждения',
          protectedBtn: 'Защитить аккаунт',
          next: 'Далее',
          modalTitle: 'Защита аккаунта с помощью двухфакторной аутентификации',
          cancel: 'Отменить',
          back: 'Назад',
          sendCode: 'Отправить код',
          code: 'Код',
          twoFa: 'Двухфакторная аутентификация',
          remember_me: 'Запомнить меня',
          activatedMsg: 'Двухфакторная аутентификация активирована',
          emptyCode: 'Пустой код подтверждения',
          invalidCode: 'Неверный код подтверждения',
          qrDescr: 'Scan the QR code with two-step verification apps <1>(Google Authenticator or Duo Authentication)</1> and enter the code you received'
        },

        common: {
          phone: '+7 (495) 984 33 64',
          email: 'support@facct.ru',
          gibLink: 'https://facct.ru/',
        },

        errors: {

          user_not_found: 'Пользователь не найден',
          passwords_not_equal: 'Разные пароли',
          passwords_is_too_short: 'Пароль должен иметь длину минимум 8 символов',
          password_is_empty: 'Укажите пароль',
          old_password_is_empty: 'Укажите старый пароль',
          new_password_is_empty: 'Укажите новый пароль',
          repeat_password_is_empty: 'Укажите новый пароль еще раз',
          token_expired: 'Токен просрочен',
          password_is_too_popular: 'Пароль находится в топе популярных паролей. Пожалуйста, укажите другой пароль',
          settings_not_found: 'Ваш аккаунт не поддерживает вход через SAML SSO. Пожалуйста, войдите, используя ваш логин и пароль или свяжитесь с вашим менеджером в F.A.C.C.T.',
          unknown_error: 'Неизвестная ошибка',
          old_password_invalid: 'Старый пароль неверный',
          used_password: 'Вы уже использовании этот пароль раньше',
          email_invalid: 'Неправильный электронный адрес',
          password_invalid: 'Пароль неправильный',
          password_expired: 'Срок действия пароля истёк. Пожалуйста, установите новый',
          user_locked: 'Ваш аккаунт заблокирован. Пожалуйста, свяжитесь с вашим менеджером',
          login_or_password_invalid: 'Логин или пароль неправильные',
          login_is_empty: 'Укажите логин',
          reset_password_token_not_active: 'Ссылка не активна',
          password_striction: 'Пароль должен содержать не менее восьми символов, как минимум одно число, прописную и строчную букву и специальный символ',
          need_redirect_to_another_region: 'Внимание! Вы будете автоматически перенаправлены на домен sso.group-ib.com'

        },

        helm: {

        }
      },
    },
  });

export default i18n
