import React from 'react';

export const TwoFaCardIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="64" viewBox="0 0 80 64" fill="none">
            <path d="M12 12.5714L32 4L52 12.5714V33.9516C52 41.3333 48.4351 48.2607 42.4283 52.5512L32 60L21.5717 52.5512C15.5649 48.2607 12 41.3333 12 33.9516V12.5714Z" fill="white" fill-opacity="0.12" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M32 19.4285C30.1064 19.4285 28.5714 20.9636 28.5714 22.8571V26.8571H26.2857V22.8571C26.2857 19.7012 28.8441 17.1428 32 17.1428C35.1559 17.1428 37.7143 19.7012 37.7143 22.8571V23.4104H35.4286V22.8571C35.4286 20.9636 33.8935 19.4285 32 19.4285Z" fill="#FC534E" fill-opacity="0.6" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8571 28.5714C22.8571 27.9403 23.3688 27.4286 24 27.4286H40C40.6312 27.4286 41.1428 27.9403 41.1428 28.5714V34.2857C41.1428 39.3352 37.0494 43.4286 32 43.4286C26.9505 43.4286 22.8571 39.3352 22.8571 34.2857V28.5714ZM33.1429 39.4286C33.1429 40.0598 32.6312 40.5714 32 40.5714C31.3689 40.5714 30.8572 40.0598 30.8572 39.4286C30.8572 38.7974 31.3689 38.2857 32 38.2857C32.6312 38.2857 33.1429 38.7974 33.1429 39.4286ZM33.1428 30.8572H30.8571V36.5714H33.1428V30.8572Z" fill="#FC534E" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M52 33.9516V12.5714L32 4V60L42.4283 52.5512C48.4351 48.2607 52 41.3333 52 33.9516Z" fill="white" fill-opacity="0.08" />
        </svg>

    )
}