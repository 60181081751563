import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Error = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 14px;
    padding-left: 12px;
    color: #FC534E;
    mix-blend-mode: normal;
`;

function ErrorDescription(props) {

    const { t } = useTranslation('errors');

    const errors = {
        user_not_found: t('user_not_found'),
        passwords_not_equal: t('passwords_not_equal'),
        passwords_is_too_short: t('passwords_is_too_short'),
        password_is_empty: t('password_is_empty'),
        old_password_is_empty: t('old_password_is_empty'),
        new_password_is_empty: t('new_password_is_empty'),
        repeat_password_is_empty: t('repeat_password_is_empty'),
        token_expired: t('token_expired'),
        old_password_invalid: t('old_password_invalid'),
        used_password: t('used_password'),
        email_invalid: t('email_invalid'),
        password_invalid: t('password_invalid'),
        password_expired: t('password_expired'),
        user_locked: t('user_locked'),
        login_or_password_invalid: t('login_or_password_invalid'),
        login_is_empty: t('login_is_empty'),
        reset_password_token_not_active: t('reset_password_token_not_active'),
        password_striction: t('password_striction'),
        password_is_too_popular: t('password_is_too_popular'),
        settings_not_found: t('settings_not_found'),
        unknown_error: t('unknown_error'),
        need_redirect_to_another_region: t('need_redirect_to_another_region'),
    };

    return(
        <Error {...props} >{errors[props.code] || props.code}</Error>
    );

}

export default ErrorDescription;
