import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Text } from '@gib-ui/core/Text';
import { Select } from '@gib-ui/core/Select';
import { Button } from '@gib-ui/core/Button';

import AppContext from '../../Components/Context/AppContext'
import './CompanySelect.css';

Modal.setAppElement('#root');

const TextRender = (className, option) => (
    <Text className={className} type="body1">
        {option.content}
    </Text>
);

export const CompanySelect = ({ isOpen, trialLink, onRequestClose, ajax, ajaxRequestToInitTrial = () => {} }) => {
    const at = useTranslation('authentication').t;
    const appContext = useContext(AppContext);
    const [selectedCompany, setSelectedCompany] = useState({});

    const handleChangeCompany = (event) => {
        setSelectedCompany(event.target.value);
    }

    const handleCancelClick = () => {
        setSelectedCompany({});
        onRequestClose();
    }

    const handleRequestClose = () => {
        setSelectedCompany({});
        onRequestClose();
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleRequestClose}
            className="CompanySelect-Modal"
            overlayClassName="CompanySelect-Overlay"
        >
            <Text as="h4" color="high" type="h4" className="CompanySelect-Title">{at('selectCompany')}</Text>
            <Text color="high" type="body3" className="CompanySelect-Label">{at('selectLabel')}</Text>
            <Select
                view="filled"
                label={at('companyName')}
                className="CompanySelect-Select"
                placeholder={at('selectCompany')}
                value={selectedCompany}
                options={appContext.sessionParams.companies ? appContext.sessionParams.companies.map(({ id, name }) => ({ value: id, content: name })) : []}
                onChange={handleChangeCompany}
                valueRender={TextRender}
                width="max"
                popupWidth="max"
            />
            <div className="CompanySelect-Controls">
                <Button view="outline" style={{ marginRight: '4px' }} onClick={handleCancelClick}>{at('cancel')}</Button>

                {
                    ajax

                    ?
                        <Button onClick={() => ajaxRequestToInitTrial(!selectedCompany.value ? trialLink : `${trialLink}&company_id=${selectedCompany.value}`)} view="filled-primary">
                            {at('tryDemo')}
                        </Button>
                    :
                        <Button href={!selectedCompany.value ? trialLink : `${trialLink}&company_id=${selectedCompany.value}`} as="a" view="filled-primary">
                            {at('tryDemo')}
                        </Button>
                }

            </div>
        </Modal>
    )
}
